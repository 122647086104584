import { getAnalyzerComponents, getAnalyzers } from "../../../services/requests";
import { EditTableProps } from "../../UI/view/FormItems/EditTable";
import { FormItemType } from "../../UI/view/component-map";
import { ListViewColumnType } from "../../UI/view/list-view";

export const analyzersEditTable = {
  name: 'locationAnalyzers',
  type: FormItemType.editTable,
  columns: [
    {
      type: ListViewColumnType.delete,
      accessor: 'delete',
    },
    {
      accessor: 'analyzerId',
      Header: 'Analyzer - Model',
      fixedWidth: 260,
      formItemProps: {
        name: 'analyzerId',
        required: true,
        defaultValue: null,
        type: FormItemType.select,
        options: async (state) => {
          const res = await getAnalyzers();
          const list = res.map(r => ({id: r.id, text: r.deviceName + " - " + r.model}));
          return [{ id: null, text: '-- Select --' }, ...list];
        },
        disabled: (watch, rowIdentity) => {
          let analyzerId = watch(`locationAnalyzers[${rowIdentity}][id]`); 
          console.log(analyzerId)
          return analyzerId && analyzerId > 0;
        }
      }
    },
    {
      accessor: 'analyzerComponentId',
      Header: 'Component',
      fixedWidth: 120,
      formItemProps: {
        name: 'analyzerComponentId',
        required: true,
        defaultValue: null,
        type: FormItemType.select,
        options: async (state, getValues, editTableProps) => {
          let analyzerId = getValues(`locationAnalyzers[${editTableProps.rowIdentity}][analyzerId]`);
          const analyzers = await getAnalyzers();
          const thisAnalyzer = analyzers.find(a => a.id == Number(analyzerId));
          if(!thisAnalyzer) return [];
          const res = await getAnalyzerComponents();
          const list = res.filter(ac => {
            if (ac.componentText == "Moisture" && thisAnalyzer.moistureLdl != null)
                return true;
            else if (ac.componentText == "THC" && thisAnalyzer.thcldl != null)
              return true;
            else if (ac.componentText == "Methane" && thisAnalyzer.methaneLdl != null)
              return true;
            else if (ac.componentText == "Oxygen" && thisAnalyzer.oxygenLdl != null)
              return true;
            else if (ac.componentText == "Nitrogen" && thisAnalyzer.nitrogenLdl != null)
              return true;
            else if (ac.componentText == "Neon" && thisAnalyzer.neonLdl != null)
              return true;
            else if (ac.componentText == "Hydrogen" && thisAnalyzer.hydrogenLdl != null)
              return true;
            else if (ac.componentText == "Carbon Dioxide" && thisAnalyzer.carbonDioxideLdl != null)
              return true;
            else if (ac.componentText == "Carbon Monoxide" && thisAnalyzer.carbonMonoxideLdl != null)
              return true;
            else if (ac.componentText == "Argon" && thisAnalyzer.argonLdl != null)
              return true;
          }).map(r => ({id: r.id, text: r.component}));
          return [{ id: null, text: '-- Select --' }, ...list];
        },
        disabled: (watch, rowIdentity) => {
          let analyzerId = watch(`locationAnalyzers[${rowIdentity}][id]`); 
          console.log(analyzerId)
          return analyzerId && analyzerId > 0;
        }
      }
    },
    {
      accessor: 'tag',
      Header: 'Tag',
      formItemProps: {
        name: 'tag',
        defaultValue: null,
        maxLength: 50
      }
    },
    {
      accessor: 'serial',
      Header: 'Serial',
      formItemProps: {
        name: 'serial',
        defaultValue: null,
        maxLength: 100
      }
    },
    {
      accessor: 'active',
      hidden: true,
      formItemProps: {
        name: 'active',
        defaultValue: true
      }
    },
    {
      accessor: 'msaoverrideLdl',
      hidden: true,
      formItemProps: {
        name: 'msaoverrideLdl',
        defaultValue: 0,
        number: true
      }
    },
    {
      accessor: 'msaTag',
      hidden: true,
      formItemProps: {
        name: 'msaTag',
        defaultValue: false,
        type: FormItemType.checkbox
      }
    },
  ]

};

/* 
Analyzer - Model	Component	LDL	Tag	Serial */


/* 
[Key]
public int Id { get; set; }
public string LocationId { get; set; } = null!;
public int AnalyzerId { get; set; }
public int AnalyzerComponentId { get; set; }
public string? Tag { get; set; }
public string? Serial { get; set; }
public bool Msatag { get; set; }
public bool? Active { get; set; }
public decimal MsaoverrideLdl { get; set; }
public string? MsafileName { get; set; }
public byte[]? MsafileData { get; set; } */