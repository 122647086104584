import { createConnectedListView, ListView, ListViewColumnType } from '../../UI/view/list-view';
import { getCustomerGasContainerHistory } from '../../../services/requests';
import { DataSource } from '../../UI/view/data-source';

export const gasHistoryListView = {
  columns: [
    {
      Header: 'Container',
      accessor: 'containerID',
      type: ListViewColumnType.link,
      linkPath: ({ val, row }) => '/assets/gas-container/' + row.original.containerID
    },
    {
      Header: 'Trk #',
      accessor: 'trackingNumberID',
    },
    {
      Header: 'Location',
      accessor: 'locationID'
    },
    {
      Header: 'Arrival',
      accessor: 'arriveDate',
      type: ListViewColumnType.datetime

    },
    {
      Header: 'Fill',
      accessor: 'fillDate',
      type: ListViewColumnType.datetime
    },
    {
      Header: 'Ship',
      accessor: 'shipDate',
      type: ListViewColumnType.datetime,
      defaultSort: true,
      defaultSortDirection: 'desc'
    },
  ],
} as ListView

export const gasHistoryDataSource = {
  request: getCustomerGasContainerHistory,
  requestArgs: (state) => {
    return state.gascustomers.current.data.customerId;
  },
  name: 'gasHistory',
  pk: 'trackingNumberID'
} as DataSource

export const GasHistoryListViewComponent = createConnectedListView(gasHistoryListView, gasHistoryDataSource);