import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  createChassisAttachmentRequest, createChassisRequest, deleteChassisAttachment,
  getChassis, getGasAndChassisReadings,
  getMaintenanceStatusForAssets, getOneChassis, updateChassisRequest,
} from '../../services/requests';

const initialState = {
  chassis: {},
  fetchGasAndChassisReadings: {
    status: 'idle',
    error: null,
  },
  chassisFetch: {
    status: 'idle',
    error: null,
  },
  chassisFetchSingle: {
    status: 'idle',
    error: null,
  },
  currentChassis: {
    chassisId: null,
    status: 'idle',
    error: null,
  },
  chassisCreate: {
    status: 'idle',
    error: null,
  },
  chassisUpdate: {
    status: 'idle',
    error: null,
  },
  chassisAttachmentCreate: {
    status: 'idle',
    error: null,
  },
  chassisAttachmentRemove: {
    status: 'idle',
    error: null,
  },
  chassisMaintenanceStatuses: {},
  chassisMaintenanceStatusFetch: {
    status: 'idle',
    error: null,
  },

};

export const fetchGasAndChassisReadings = createAsyncThunk('chassisContainer/fetchLiquidContainerReadings', async (arg) => {
  const { serialNumber, range } = arg;
  const response = await getGasAndChassisReadings('Chassis', serialNumber, range);
  return response;
});

export const createChassis = createAsyncThunk('chassis/createChassis', async (user) => {
  const response = await createChassisRequest(user);
  return response;
});

export const updateChassis = createAsyncThunk('chassis/updateChassis', async (user, thunkAPI) => {
  const response = await updateChassisRequest(user);
  return response;
});

export const fetchChassis = createAsyncThunk('chassis/fetchChassis', async () => {
  const response = await getChassis();
  return response;
});

export const fetchOneChassis = createAsyncThunk('chassis/fetchOneChassis', async (serialNumber) => {
  const response = await getOneChassis(serialNumber);
  return response;
});

export const createChassisAttachment = createAsyncThunk('attachment/createChassisAttachment', async (attachment, thunkAPI) => {
  const response = await createChassisAttachmentRequest(attachment);
  return response;
});

export const removeChassisAttachment = createAsyncThunk('attachment/deleteChassisAttachment', async (attachmentId) => {
  const response = await deleteChassisAttachment(attachmentId);
  return response;
});

export const fetchMaintenanceStatusForChassis = createAsyncThunk(
  'chassis/fetchMaintenanceStatusForChassis',
  async ({ assetTypeId, assetIds }) => {
    const response = await getMaintenanceStatusForAssets(assetTypeId, assetIds);
    return response;
  },
);

export const selectChassis = (state) => state.chassis.chassis;
export const selectCurrentChassisId = (state) => state.chassis.currentChassis.chassisId;

export const selectCurrentChassis = createSelector([
  selectChassis,
  selectCurrentChassisId], (chassis, chassisId) => {
  if (chassisId == null) {
    return {};
  }
  return chassis[chassisId];
});

export const chassisSlice = createSlice({
  name: 'chassis',
  initialState,
  reducers: {
    setChassis: (state, action) => {
      state.chassis = action.payload.reduce((current, c) => (
        { ...current, [c.serialNumber]: c }), {});
    },
    setCurrentChassisId: (state, action) => {
      state.currentChassis.chassisId = action.payload;
    },
    setChassisUpdateStatus: (state, action) => {
      state.chassisUpdate.status = action.payload;
    },
    setChassisCreateStatus: (state, action) => {
      state.chassisCreate.status = action.payload;
    },
    setCreateAttachmentStatus: (state, action) => {
      state.chassisAttachmentCreate.status = action.payload;
    },
    setRemoveAttachmentStatus: (state, action) => {
      state.chassisAttachmentRemove.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGasAndChassisReadings.pending, (state, action) => {
        state.fetchGasAndChassisReadings.status = 'loading';
      })
      .addCase(fetchGasAndChassisReadings.fulfilled, (state, action) => {
        state.fetchGasAndChassisReadings.status = 'succeeded';
        state.chassisReadings = {
          ...state.chassisReadings,
          [state.currentChassis.chassisId]: {
            ...action.payload,
          },
        };
      })
      .addCase(fetchGasAndChassisReadings.rejected, (state, action) => {
        state.fetchGasAndChassisReadings.status = 'failed';
        state.fetchGasAndChassisReadings.error = action.error.message;
      });
    builder
      .addCase(fetchChassis.pending, (state, action) => {
        state.chassisFetch.status = 'loading';
      })
      .addCase(fetchChassis.fulfilled, (state, action) => {
        state.chassisFetch.status = 'succeeded';
        // state.chassis = state.chassis.concat(action.payload)
        state.chassis = action.payload.reduce((current, p) => (
          { ...current, [p.serialNumber]: p }), {});
      })
      .addCase(fetchChassis.rejected, (state, action) => {
        state.chassisFetch.status = 'failed';
        state.chassisFetcherror = action.error.message;
      });
    builder
      .addCase(fetchOneChassis.pending, (state, action) => {
        state.chassisFetchSingle.status = 'loading';
      })
      .addCase(fetchOneChassis.fulfilled, (state, action) => {
        state.chassisFetchSingle.status = 'succeeded';
        state.chassis = {
          ...state.chassis,
          [action.payload.assetDetails.serialNumber]: {
            ...action.payload.assetDetails,
            attachmentList: action.payload.attachmentList,
          },
        };
      })
      .addCase(fetchOneChassis.rejected, (state, action) => {
        state.chassisFetchSingle.status = 'failed';
        state.chassisFetchSingle.error = action.error.message;
      });
    builder
      .addCase(createChassisAttachment.pending, (state, action) => {
        state.chassisAttachmentCreate.status = 'loading';
      })
      .addCase(createChassisAttachment.fulfilled, (state, action) => {
        state.chassisAttachmentCreate.status = 'succeeded';
        const newAttachmentList = JSON.parse(JSON.stringify([...state.chassis[state.currentChassis.chassisId].attachmentList]));
        const exists = newAttachmentList.filter((x) => x.attachmentId === action.payload.attachmentId);
        if (exists.length === 0) newAttachmentList.push(action.payload);
        state.chassis = {
          ...state.chassis,
          [state.currentChassis.chassisId]: {
            ...state.chassis[state.currentChassis.chassisId],
            attachmentList:
            [
              ...newAttachmentList,
            ],
          },
        };
      })
      .addCase(createChassisAttachment.rejected, (state, action) => {
        state.chassisAttachmentCreate.status = 'failed';
        state.chassisAttachmentCreate.error = action.error.message;
      });
    builder
      .addCase(removeChassisAttachment.pending, (state, action) => {
        state.chassisAttachmentRemove.status = 'loading';
      })
      .addCase(removeChassisAttachment.fulfilled, (state, action) => {
        state.chassisAttachmentRemove.status = 'succeeded';
        state.chassis = {
          ...state.chassis,
          [state.currentChassis.chassisId]: {
            ...state.chassis[state.currentChassis.chassisId],
            attachmentList:
              state.chassis[state.currentChassis.chassisId]
                .attachmentList
                .filter((attachment) => attachment.attachmentId !== action.meta.arg),
          },
        };
      })
      .addCase(removeChassisAttachment.rejected, (state, action) => {
        state.chassisAttachmentRemove.status = 'failed';
        state.chassisAttachmentRemove.error = action.error.message;
      });
    builder
      .addCase(createChassis.pending, (state, action) => {
        state.chassisCreate.status = 'loading';
      })
      .addCase(createChassis.fulfilled, (state, action) => {
        state.chassisCreate.status = 'succeeded';
        state.chassis = { ...state.chassis, [action.payload.serialNumber]: action.payload };
        state.currentChassis.chassisId = action.payload.serialNumber;
        state.chassisCreate.error = null;
      })
      .addCase(createChassis.rejected, (state, action) => {
        state.chassisCreate.status = 'failed';
        state.chassisCreate.error = action.error.message;
      });

    builder
      .addCase(updateChassis.pending, (state, action) => {
        state.chassisUpdate.status = 'loading';
      })
      .addCase(updateChassis.fulfilled, (state, action) => {
        state.chassisUpdate.status = 'succeeded';
        state.chassis = {
          ...state.chassis,
          [action.payload.serialNumber]: {
            ...state.chassis[action.payload.serialNumber],
            ...action.payload,
          },
        };
        state.chassisUpdate.error = null;
      })
      .addCase(updateChassis.rejected, (state, action) => {
        state.chassisUpdate.status = 'failed';
        state.chassisUpdate.error = action.error.message;
      });

    builder
      .addCase(fetchMaintenanceStatusForChassis.pending, (state, action) => {
        state.chassisMaintenanceStatusFetch.status = 'loading';
      })
      .addCase(fetchMaintenanceStatusForChassis.fulfilled, (state, action) => {
        state.chassisMaintenanceStatusFetch.status = 'succeeded';
        state.chassisMaintenanceStatuses = {
          ...state.chassisMaintenanceStatuses,
          ...action.payload,
        };
        state.chassisMaintenanceStatusFetch.error = null;
      })
      .addCase(fetchMaintenanceStatusForChassis.rejected, (state, action) => {
        state.chassisMaintenanceStatusFetch.status = 'failed';
        state.chassisMaintenanceStatusFetch.error = action.error.message;
      });
  },
});

export const {
  setChassis, setCurrentChassisId,
  setChassisCreateStatus, setChassisUpdateStatus,
  setCreateAttachmentStatus, setRemoveAttachmentStatus,
} = chassisSlice.actions;

export const selectChassisMaintenanceStatuses = (
  state,
) => state.chassis.chassisMaintenanceStatuses;

export const selectAllChassis = (state) => state.chassis.chassisReadings;

export const selectCurrentChassisReadings = createSelector([
  selectAllChassis,
  selectCurrentChassisId], (containers, containerId) => {
  if (!containerId || !containers || !containers[containerId]) {
    return {
      myTemp: [], myBattV: [], myLoc: [], myReadings: [],
    };
  }
  return containers[containerId];
});

export default chassisSlice.reducer;
