import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { checkFormIsDirty } from '../../../services/form-validation';
import { convertDateToUTCDay, formatDate } from '../../../services/format';
import { getDueDate, getLatestRehab } from '../../../services/maintenance-helpers';
import { selectIsAssetAdmin, selectLoggedInUserInfo, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { fetchDropdownValues, selectAllActiveLocations } from '../../../store/slices/legacy-slice';
import { updateLiquidContainer } from '../../../store/slices/liquid-containers-slice';
import { selectLiquidContainersWithMaintenanceInfo, selectMaintenanceRequestTypes } from '../../../store/slices/maintenance-requests-slice';
import BasicInput from '../../UI/molecules/BasicInput/basic-input';
import Card from '../../UI/molecules/Card/card';
import DateInput from '../../UI/molecules/DateInput/date-input';
import {
  liquidContainerManufacturers, liquidContainerMawps, liquidContainerRegulatoryDocuments,
  liquidContainerRehabCycleYears,
  liquidContainerShieldTypes,
  liquidContainerSpecialPermits,
  liquidContainerStyleTypes,
  liquidContainerTypes,
} from './liquid-container-values';

function LiquidContainerForm({ currentLiquidContainer, liquidContainerStatusIsLoading }) {
  const defaultLiquidContainerFormValues = {
    serialNumber: '',
    ownerCustomer: null,
    inServiceDate: null,
    manufacturer: null,
    containerTypeCode: null,
    specialPermit: null,
    regulatoryDocument: null,
    containerMawp: null,
    oneWayTravelTime: null,
    oneWayTravelTimeActual: null,
    hasHeliumShield: null,
    shieldStyle: null,
    containerStyle: null,
    nominalCapacity: null,
    nominalWeight: null,
    tareWeight: null,
    dryTareWeight: null,
    lniWeight: null,
    lastWeightVerifiedLocation: null,
    lastDateWeightVerified: null,
    previousContainerNumbers: null,
    kingPinInspectionDate: null,
    telemetryUnitId: null,
    comments: null,
    isSkikdaAuthorized: null,
    isSkikdaNoFlyList: null,
    skikdaAuthorizationDate: null,
    skikdaNoFlyListComments: null,
    isActive: null,
    isPbcRequired: null,
    // isIgnoreCscDates: null,
    hasRemoteReadoutConnections: null,
    isDualSlwValves: null,
    isLeased: null,
    leasedTo: null,
    leasedFrom: null,
    lastFiveYearInspectionDate: null,
    cscDate: null,
    eInspectionDate: null,
    vInspectionDate: null,
    lastRebuildDate: null,
    aInspectionDate: null,
    bInspectionDate: null,
    vacInspectionDate: null,
    rehabCycleYrs: null,
    manufacturerSerialNumber: null,
    trickleValve: false,
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty, dirtyFields },
    getFieldState,
    resetField,
  } = useForm({
    defaultValues: defaultLiquidContainerFormValues,
    mode: 'all',
  });
  const { datePreference } = useSelector(selectLoggedInUserPreferences);
  const userInfo = useSelector(selectLoggedInUserInfo);
  const dispatch = useDispatch();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [computedTareWeight, setComputedTareWeight] = useState(null);
  // form will only be used to update b/c we will create a creat specific modal
  const onSubmit = (liquidContainer) => {
    Object.keys(liquidContainer).forEach((key) => {
      if (key.toLowerCase().includes('date') && liquidContainer[key]) {
        liquidContainer[key] = convertDateToUTCDay(liquidContainer[key]);
      }
    });
    dispatch(updateLiquidContainer({ ...liquidContainer, assetType: 'LiquidContainer' }));
    setFormIsDirty(false);
  };

  // Populate form with default values
  useEffect(() => {
    if (currentLiquidContainer) {
      Object.keys(defaultLiquidContainerFormValues).forEach((key) => {
        if (key.toLowerCase().includes('date') && currentLiquidContainer[key]) {
          const dateObj = new Date(currentLiquidContainer[key].replace('Z', ''));
          if (Number.isNaN(dateObj.getTime())) {
            setValue(key, null);
          } else {
            setValue(key, dateObj);
          }
        } else {
          setValue(key, currentLiquidContainer[key]);
        }
        if (['oneWayTravelTime', 'oneWayTravelTimeActual'].includes(key)) {
          trigger(key);
        }
      });
    }
  }, [currentLiquidContainer]);

  // Set shield style to null if helium shield is false
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setFormIsDirty(checkFormIsDirty(value, currentLiquidContainer));
      if (name === 'hasHeliumShield' && value.hasHeliumShield === false) {
        setValue('shieldStyle', null);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, currentLiquidContainer]);

  const basicInputProps = {
    setValue,
    watch,
    getFieldState,
    register,
    trigger,
  };

  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  const locations = useSelector(selectAllActiveLocations);
  const maintenanceRequestTypes = useSelector(
    selectMaintenanceRequestTypes,
  );
  const maintenanceRequests = useSelector(selectLiquidContainersWithMaintenanceInfo);
  const assetMaintenanceRequests = maintenanceRequests && currentLiquidContainer?.serialNumber ? maintenanceRequests[currentLiquidContainer.serialNumber].maintenanceRequests : null;
  const liquidCustomers = useSelector(((state) => state.legacy?.dropdownValues?.liquidCustomers))
   ?? [];
  const liquidCustomersStatus = useSelector((state) => state.legacy.status);

  useEffect(() => {
    if (liquidCustomersStatus === 'idle' || liquidCustomersStatus === 'failed') {
      dispatch(fetchDropdownValues());
    }
  }, []);

  const sortedCustomers = useMemo(() => {
    return [...liquidCustomers].filter((cust) => cust.Status === 'Active' || cust.CustomerID === currentLiquidContainer?.ownerCustomer?.toString())
      .sort((a, b) => a.CustomerName.trim().localeCompare(b.CustomerName.trim()));
  }, [liquidCustomers, currentLiquidContainer]);

  const latestRehab = assetMaintenanceRequests ? getLatestRehab(assetMaintenanceRequests, maintenanceRequestTypes) : null;
  const RebuildWidget = (
    <Row>
      <Col>
        <Form.Group className="mb-1">
          <Form.Label>Last Rebuild</Form.Label>
          <DateInput
            control={control}
            formControlName="lastRebuildDate"
          />
        </Form.Group>
      </Col>
      {latestRehab && (
      <>
        <Col>
          <Form.Group className="mb-1">
            <Form.Label>Rehab</Form.Label>
            <NavLink to={`/maintenance/work-orders/${latestRehab.workOrderId}`} style={{ display: 'block' }}>{latestRehab.workOrderId}</NavLink>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            <Form.Label>Rehab Due</Form.Label>
            <NavLink to={`/maintenance/work-orders/${latestRehab.workOrderId}`} style={{ display: 'block' }}>{formatDate(getDueDate(latestRehab, maintenanceRequestTypes), datePreference)}</NavLink>
          </Form.Group>
        </Col>
      </>
      )}
    </Row>
  );

  const computeTareWeight = () => {
    const { dryTareWeight, lniWeight } = getValues();
    const computed = dryTareWeight + lniWeight;
    setValue('tareWeight', computed, { shouldTouch: true });
    setComputedTareWeight(computed);
    return computed;
  };

  return (
    <>
      <Form className="overflow-auto pt-3 flex-grow-1">
        <Spinner animation="border" variant="primary" className={`create-spinner${liquidContainerStatusIsLoading ? ' visible' : ' invisible'}`} />
        <fieldset disabled={!isAssetAdmin}>
          <Container fluid className={` ${liquidContainerStatusIsLoading ? ' creation-loading' : ''}`}>
            <Row>
              <Col>
                <Card header="Info" className="card-secondary card-outline">
                  <Form.Group className="mb-1">
                    <Form.Label>Owner</Form.Label>
                    <Form.Select size="sm" {...register('ownerCustomer', { required: 'Please select an owner.' })} isInvalid={!!errors.ownerCustomer}>
                      {sortedCustomers
                        .map((customer, index) => (
                          <option value={customer.CustomerID} key={`customer-${index}`}>{customer.CustomerName}</option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.ownerCustomer && errors.ownerCustomer.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>In Service Date</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="inServiceDate"
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Serial Number</Form.Label>
                    <Form.Control disabled size="sm" {...register('serialNumber', { required: 'Please enter a serial number.' })} isInvalid={!!errors.serialNumber} />
                    <Form.Control.Feedback type="invalid">
                      {errors.serialNumber && errors.serialNumber.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Container className="p-0 m-0">
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Manufacturer</Form.Label>
                          <Form.Select
                            size="sm"
                            {...register('manufacturer', { required: 'Please select a manufacturer.' })}
                            isInvalid={!!errors.manufacturer}
                          >
                            {liquidContainerManufacturers.map((manufacturer, i) => (
                              <option
                                value={manufacturer}
                                key={manufacturer}
                              >
                                {manufacturer}

                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.manufacturer && errors.manufacturer.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Manufacturer Serial Number</Form.Label>
                          <Form.Control size="sm" {...register('manufacturerSerialNumber')} isInvalid={!!errors.manufacturerSerialNumber} />
                          <Form.Control.Feedback type="invalid">
                            {errors.manufacturerSerialNumber
                            && errors.manufacturerSerialNumber.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Type</Form.Label>
                          <Form.Select size="sm" {...register('containerTypeCode')}>
                            {liquidContainerTypes.map((type, i) => (
                              <option value={i} key={type}>{type}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Style</Form.Label>
                          <Form.Select size="sm" {...register('containerStyle', { required: 'Please select a containerStyle.' })} isInvalid={!!errors.containerStyle}>
                            {liquidContainerStyleTypes.map((item, i) => (
                              <option value={i} key={`containerStyle-${i}`}>{item}</option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.containerStyle && errors.containerStyle.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Special Permit</Form.Label>
                          <Form.Select size="sm" {...register('specialPermit', { required: 'Please select a specialPermit.' })} isInvalid={!!errors.specialPermit}>
                            {liquidContainerSpecialPermits.map((permit, i) => (
                              <option
                                value={i}
                                key={permit}
                              >
                                {liquidContainerSpecialPermits[i]}

                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.specialPermit && errors.specialPermit.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Regulatory Document</Form.Label>
                          <Form.Select size="sm" {...register('regulatoryDocument')}>
                            {liquidContainerRegulatoryDocuments.map((document, i) => (
                              <option value={i} key={document}>{document}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>MAWP</Form.Label>
                          <Form.Select size="sm" {...register('containerMawp', { required: 'Please select a MAWP.' })} isInvalid={!!errors.containerMawp}>
                            {liquidContainerMawps.map((mawpValue, i) => (
                              <option value={i} key={`mawpValues-${i}`}>{mawpValue}</option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.containerMawp && errors.containerMawp.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>One Way Travel Time Design (days)</Form.Label>
                          <Form.Select size="sm" {...register('oneWayTravelTime', { required: 'Please select a one way travel time in days.', min: { value: 0, message: 'Please select a one way travel time in days.' } })} isInvalid={!!errors.oneWayTravelTime}>
                            <option key="oneWayTravelTime-1" value={0}>0</option>
                            <option key="oneWayTravelTime-2" value={25}>25</option>
                            <option key="oneWayTravelTime-3" value={30}>30</option>
                            <option key="oneWayTravelTime-4" value={35}>35</option>
                            <option key="oneWayTravelTime-5" value={40}>40</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.oneWayTravelTime && errors.oneWayTravelTime.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>One Way Travel Time Actual (days)</Form.Label>
                          <Form.Control size="sm" min="0" {...register('oneWayTravelTimeActual', { required: 'Please enter a one way travel time in days.', min: { value: 0, message: 'must be non-negative' } })} isInvalid={!!errors.oneWayTravelTimeActual} />
                          <Form.Control.Feedback type="invalid">
                            {errors.oneWayTravelTimeActual && errors.oneWayTravelTimeActual.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>

                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Shield Style</Form.Label>
                          <Form.Select
                            size="sm"
                            disabled={!watch('hasHeliumShield')}
                            {...register(
                              'shieldStyle',
                              { required: watch('hasHeliumShield') ? 'You must enter a shield style if container is shielded.' : false },
                            )}
                            isInvalid={!!errors.shieldStyle && watch('hasHeliumShield')}
                          >
                            <option value={null} key="shieldStyle-null" />
                            {liquidContainerShieldTypes.map((shieldStyle, i) => (
                              <option value={i} key={shieldStyle}>{shieldStyle}</option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.shieldStyle && errors.shieldStyle.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Nominal Capacity</Form.Label>
                          <Form.Control
                            type="number"
                            min="0"
                            size="sm"
                            {...register('nominalCapacity', {
                              required: 'Please enter a Nominal Capacity.',
                              min: { value: 35000, message: 'must be between 35000 and 2000000' },
                              max: { value: 2000000, message: 'must be between 35000 and 2000000' },
                              pattern: {
                                value: /^[0-9]*$/,
                                message: 'Value must be a number',
                              },
                            })}
                            isInvalid={!!errors.nominalCapacity}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.nominalCapacity && errors.nominalCapacity.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Nominal Weight</Form.Label>
                          <BasicInput
                            name="nominalWeight"
                            defaultUnit="lbs"
                            options={['lbs', 'kgs']}
                            isInvalid={!!errors.nominalWeight}
                            {...basicInputProps}
                            initialValue={currentLiquidContainer?.nominalWeight}
                            registerOptions={{
                              min: { value: 1000, message: 'must be between 1000 and 14000 lbs' },
                              max: { value: 14000, message: 'must be between 1000 and 14000 lbs' },
                            }}
                            valueIsLoaded={!liquidContainerStatusIsLoading}
                          />
                          {errors.nominalWeight && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.nominalWeight.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Wet Tare Weight</Form.Label>
                          <BasicInput
                            onCalculate={() => { return computeTareWeight(); }}
                            name="tareWeight"
                            defaultUnit="lbs"
                            options={['lbs', 'kgs']}
                            isInvalid={!!errors.tareWeight}
                            {...basicInputProps}
                            initialValue={computedTareWeight || currentLiquidContainer?.tareWeight}
                            errorInfo={{ type: 'custom', message: 'Please enter a Tare Weight' }}
                            valueIsLoaded={!liquidContainerStatusIsLoading}
                          />
                          {errors.tareWeight && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.tareWeight.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Dry Tare Weight</Form.Label>
                          <BasicInput
                            name="dryTareWeight"
                            defaultUnit="lbs"
                            options={['lbs', 'kgs']}
                            isInvalid={!!errors.dryTareWeight}
                            {...basicInputProps}
                            initialValue={currentLiquidContainer?.dryTareWeight}
                            errorInfo={
                              { type: 'custom', message: 'Please enter a Dry Tare Weight' }
                            }
                            valueIsLoaded={!liquidContainerStatusIsLoading}
                          />
                          {errors.dryTareWeight && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.dryTareWeight.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>LIN Weight</Form.Label>
                          <BasicInput
                            name="lniWeight"
                            defaultUnit="lbs"
                            options={['lbs', 'kgs']}
                            isInvalid={!!errors.lniWeight}
                            {...basicInputProps}
                            initialValue={currentLiquidContainer?.lniWeight}
                            errorInfo={{ type: 'custom', message: 'Please enter a LIN Weight' }}
                            valueIsLoaded={!liquidContainerStatusIsLoading}
                          />
                          {errors.lniWeight && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.lniWeight.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Weight Verified Date</Form.Label>
                          <DateInput
                            control={control}
                            formControlName="lastDateWeightVerified"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Label>Weight Verified At</Form.Label>
                        <Form.Select size="sm" {...register('lastWeightVerifiedLocation')}>
                          {(locations ?? []).map((location, index) => (
                            <option value={location.LocationID} key={`location-${index}`}>{location.LocationName}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                    <Form.Group className="mb-1">
                      <Form.Label>Previous Container Numbers</Form.Label>
                      <Form.Control size="sm" {...register('previousContainerNumbers')} />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>Comments</Form.Label>
                      <Form.Control size="sm" as="textarea" rows={3} {...register('comments')} style={{ resize: 'none' }} />
                    </Form.Group>
                  </Container>
                </Card>
              </Col>
              <Col>
                <Card header="Status" className="card-secondary card-outline">
                  <Container fluid className="d-flex flex-wrap wrap">
                    <Form.Check
                      {...register('isActive')}
                      type="switch"
                      inline
                      id="isActive-switch"
                      label="Active"
                    />
                    <Form.Check
                      {...register('isPbcRequired')}
                      type="switch"
                      inline
                      id="isPbcRequired-switch"
                      label="PBC"
                    />
                    {/* <Form.Check
                      {...register('isIgnoreCscDates')}
                      type="switch"
                      inline
                      id="isIgnoreCscDates-switch"
                      label="Ignore CSC"
                    /> */}
                    <Form.Check
                      {...register('hasRemoteReadoutConnections')}
                      type="switch"
                      inline
                      id="hasRemoteReadoutConnections-switch"
                      label="Remote Readout Conn."
                    />
                    <Form.Check
                      {...register('isDualSlwValves')}
                      type="switch"
                      inline
                      id="isDualSlwValves-switch"
                      label="Dual SLW Valves"
                    />
                    <Form.Check
                      {...register('hasHeliumShield')}
                      type="switch"
                      inline
                      id="hasHeliumShield-switch"
                      label="He Shield"
                    />
                    <Form.Check
                      {...register('isSkikdaNoFlyList')}
                      inline
                      type="switch"
                      id="isSkikdaNoFlyList-switch"
                      label="Restricted List"
                    />
                    <Form.Check
                      {...register('trickleValve')}
                      inline
                      type="switch"
                      id="trickleValve-switch"
                      label="Trickle Valve"
                    />
                  </Container>
                </Card>
                <Card header="Container Restrictions" className="mt-2 card-secondary card-outline">
                  <div className="mb-1">
                    <Form.Check
                      {...register('isSkikdaAuthorized')}
                      inline
                      type="switch"
                      id="isSkikdaAuthorized-switch"
                      label="Skikda Authorized"
                    />
                  </div>
                  <Form.Group className="mb-1">
                    <Form.Label>Skikda Authoization Date</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="skikdaAuthorizationDate"
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Container Restrictions Comments</Form.Label>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      rows={3}
                      {...register('skikdaNoFlyListComments')}
                      style={{ resize: 'none' }}
                    />
                  </Form.Group>
                </Card>
                <Card header="Lease Information" className="mt-2 card-secondary card-outline">
                  <Container className="p-0 m-0">
                    <Row>
                      <Col sm={3}>
                        <div className="d-flex align-items-center">
                          <Form.Check
                            {...register('isLeased')}
                            type="switch"
                            inline
                            id="isLeased-switch"
                            label="Leased"
                          />
                        </div>
                      </Col>
                      <Col sm={9}>
                        <Form.Group className="mb-1">
                          <Form.Label>Leased To</Form.Label>
                          <Form.Select size="sm" {...register('leasedTo')}>
                            {liquidCustomers.map((customer, index) => (
                              <option value={customer.CustomerID} key={`leased-to-key-${index}`}>{customer.CustomerName}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Form.Group className="mb-1">
                    <Form.Label>Leased From (vendor)</Form.Label>
                    <Form.Control size="sm" {...register('leasedFrom')} />
                  </Form.Group>
                </Card>
                <Card header="Maintenance" className="mt-2 card-secondary card-outline">
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last 5 Year Inspection</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="lastFiveYearInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last 2.5 Year Inspection (CSC)</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="cscDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last IPM (Intermediate PM)</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="eInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last Visual Inspection (15000 GL)</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="vInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last A Inspection</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="aInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last DOT Inspection</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="bInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {RebuildWidget}
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Rehab Cycle Years</Form.Label>
                        <Form.Select size="sm" {...register('rehabCycleYrs')}>
                          {liquidContainerRehabCycleYears.map((yearOption, index) => (
                            <option value={yearOption} key={`rehabCycleYrs-${index}`}>{yearOption}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last Vacuum Inspection</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="vacInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>King Pin Inspection Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="kingPinInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>IoT Device ID</Form.Label>
                        <Form.Control size="sm" {...register('telemetryUnitId')} />
                      </Form.Group>
                    </Col>
                    <Col />
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </fieldset>
      </Form>
      <div className="d-flex flex-row position-sticky bottom-0 p-2 pr-1 bg-body border-top">
        <Button variant="light" target="__blank" href={`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fHe3_MaintenanceStockIssue&rs:Command=Render${userInfo?.locationPreference ? `&Location=${userInfo?.locationPreference}` : ''}&Container=${currentLiquidContainer?.serialNumber}`}>
          Stock Issue Report
          <FiExternalLink style={{ paddingLeft: 2, paddingBottom: 4 }} />
        </Button>
        {isAssetAdmin && (
          <Button
            type="submit"
            disabled={liquidContainerStatusIsLoading || !formIsDirty}
            onClick={handleSubmit(onSubmit)}
            style={{ marginLeft: 'auto' }}
          >
            Update
          </Button>
        )}
      </div>
    </>
  );
}

export default LiquidContainerForm;
