import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  createLiquidAttachmentRequest,
  createLiquidContainerRequest, deleteLiquidAttachment,
  getContainerReadings,
  getLiquidContainer,
  getLiquidContainers,
  getMaintenanceStatusForAssets,
  updateLiquidContainerRequest,
} from '../../services/requests';

const initialState = {
  liquidContainers: {},
  liquidContainerReadings: {},
  fetchLiquidContainerReadings: {
    status: 'idle',
    error: null,
  },
  liquidContainersFetch: {
    status: 'idle',
    error: null,
  },
  liquidContainerFetchSingle: {
    status: 'idle',
    error: null,
  },
  currentLiquidContainer: {
    liquidContainerId: null,
    status: 'idle',
    error: null,
  },
  liquidContainerCreate: {
    status: 'idle',
    error: null,
  },
  liquidContainerUpdate: {
    status: 'idle',
    error: null,
  },
  liquidContainerAttachmentCreate: {
    status: 'idle',
    error: null,
  },
  liquidContainerAttachmentRemove: {
    status: 'idle',
    error: null,
  },
  liquidContainerMaintenanceStatuses: {},
  liquidContainersMaintenanceStatusFetch: {
    status: 'idle',
    error: null,
  },

};

export const fetchLiquidContainerReadings = createAsyncThunk('liquidContainer/fetchLiquidContainerReadings', async (arg) => {
  const { serialNumber, range } = arg;
  const response = await getContainerReadings('LiquidContainer', serialNumber, range);
  return response;
});

export const fetchLiquidContainers = createAsyncThunk('liquidContainer/fetchLiquidContainers', async () => {
  const response = await getLiquidContainers();
  return response;
});

export const fetchLiquidContainer = createAsyncThunk('liquidContainer/fetchLiquidContainer', async (serialNumber) => {
  const response = await getLiquidContainer(serialNumber);
  return response;
});

export const createLiquidContainer = createAsyncThunk('liquidContainer/createLiquidContainer', async (data) => {
  const response = await createLiquidContainerRequest(data);
  return response;
});

export const updateLiquidContainer = createAsyncThunk('liquidContainer/updateLiquidContainer', async (data, thunkAPI) => {
  const response = await updateLiquidContainerRequest(data);
  return response;
});

export const createLiquidAttachment = createAsyncThunk('attachment/createLiquidAttachment', async (attachment, thunkAPI) => {
  const response = await createLiquidAttachmentRequest(attachment);
  return response;
});

export const removeLiquidAttachment = createAsyncThunk('attachment/deleteLiquidAttachment', async (attachmentId) => {
  const response = await deleteLiquidAttachment(attachmentId);
  return response;
});

export const fetchMaintenanceStatusForLiquidContainers = createAsyncThunk(
  'liquidContainer/fetchMaintenanceStatusForLiquidContainers',
  async ({ assetTypeId, assetIds }) => {
    const response = await getMaintenanceStatusForAssets(assetTypeId, assetIds);
    return response;
  },
);

export const liquidContainerSlice = createSlice({
  name: 'liquidContainer',
  initialState,
  reducers: {
    setliquidContainers: (state, action) => {
      state.liquidContainers = action.payload.reduce((current, l) => (
        { ...current, [l.serialNumber]: l }), {});
    },
    setCurrentLiquidContainerId: (state, action) => {
      state.currentLiquidContainer.liquidContainerId = action.payload;
    },
    setLiquidContainerUpdateStatus: (state, action) => {
      state.liquidContainerUpdate.status = action.payload;
    },
    setLiquidContainerCreateStatus: (state, action) => {
      state.liquidContainerCreate.status = action.payload;
    },
    setLiquidContainerFetchStatus: (state, action) => {
      state.liquidContainersFetch.status = action.payload;
    },
    setLiquidContainerFetchSingleStatus: (state, action) => {
      state.liquidContainerFetchSingle.status = action.payload;
    },
    setCreateAttachmentStatus: (state, action) => {
      state.liquidContainerAttachmentCreate.status = action.payload;
    },
    setRemoveAttachmentStatus: (state, action) => {
      state.liquidContainerAttachmentRemove.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLiquidContainerReadings.pending, (state, action) => {
        state.fetchLiquidContainerReadings.status = 'loading';
      })
      .addCase(fetchLiquidContainerReadings.fulfilled, (state, action) => {
        state.fetchLiquidContainerReadings.status = 'succeeded';
        state.liquidContainerReadings = {
          ...state.liquidContainerReadings,
          [state.currentLiquidContainer.liquidContainerId]: {
            ...action.payload,
          },
        };
      })
      .addCase(fetchLiquidContainerReadings.rejected, (state, action) => {
        state.fetchLiquidContainerReadings.status = 'failed';
        state.fetchLiquidContainerReadings.error = action.error.message;
      });
    builder
      .addCase(fetchLiquidContainers.pending, (state, action) => {
        state.liquidContainersFetch.status = 'loading';
      })
      .addCase(fetchLiquidContainers.fulfilled, (state, action) => {
        state.liquidContainersFetch.status = 'succeeded';
        // state.liquidContainers = state.liquidContainers.concat(action.payload)
        state.liquidContainers = action.payload.reduce((current, l) => ({
          ...current,
          [l.serialNumber]: l,
        }), {});
      })
      .addCase(fetchLiquidContainers.rejected, (state, action) => {
        state.liquidContainersFetch.status = 'failed';
        state.liquidContainersFetch.error = action.error.message;
      });
    builder
      .addCase(fetchLiquidContainer.pending, (state, action) => {
        state.liquidContainerFetchSingle.status = 'loading';
      })
      .addCase(fetchLiquidContainer.fulfilled, (state, action) => {
        state.liquidContainerFetchSingle.status = 'succeeded';
        state.liquidContainers = {
          ...state.liquidContainers,
          [action.payload.assetDetails.serialNumber]: {
            ...action.payload.assetDetails,
            attachmentList: action.payload.attachmentList,
          },
        };
      })
      .addCase(fetchLiquidContainer.rejected, (state, action) => {
        state.liquidContainerFetchSingle.status = 'failed';
        state.liquidContainerFetchSingle.error = action.error.message;
      });
    builder
      .addCase(createLiquidAttachment.pending, (state, action) => {
        state.liquidContainerAttachmentCreate.status = 'loading';
      })
      .addCase(createLiquidAttachment.fulfilled, (state, action) => {
        state.liquidContainerAttachmentCreate.status = 'succeeded';
        const newAttachmentList = JSON.parse(JSON.stringify([...state.liquidContainers[state.currentLiquidContainer.liquidContainerId].attachmentList]));
        const exists = newAttachmentList.filter((x) => x.attachmentId === action.payload.attachmentId);
        if (exists.length === 0) newAttachmentList.push(action.payload);
        state.liquidContainers = {
          ...state.liquidContainers,
          [state.currentLiquidContainer.liquidContainerId]: {
            ...state.liquidContainers[state.currentLiquidContainer.liquidContainerId],
            attachmentList:
              [
                ...newAttachmentList,
              ],
          },
        };
      })
      .addCase(createLiquidAttachment.rejected, (state, action) => {
        state.liquidContainerAttachmentCreate.status = 'failed';
        state.liquidContainerAttachmentCreate.error = action.error.message;
      });
    builder
      .addCase(removeLiquidAttachment.pending, (state, action) => {
        state.liquidContainerAttachmentRemove.status = 'loading';
      })
      .addCase(removeLiquidAttachment.fulfilled, (state, action) => {
        state.liquidContainerAttachmentRemove.status = 'succeeded';
        state.liquidContainers = {
          ...state.liquidContainers,
          [state.currentLiquidContainer.liquidContainerId]: {
            ...state.liquidContainers[state.currentLiquidContainer.liquidContainerId],
            attachmentList:
              state.liquidContainers[state.currentLiquidContainer.liquidContainerId]
                .attachmentList
                .filter((attachment) => attachment.attachmentId !== action.meta.arg),
          },
        };
      })
      .addCase(removeLiquidAttachment.rejected, (state, action) => {
        state.liquidContainerAttachmentRemove.status = 'failed';
        state.liquidContainerAttachmentRemove.error = action.error.message;
      });
    builder
      .addCase(createLiquidContainer.pending, (state, action) => {
        state.liquidContainerCreate.status = 'loading';
      })
      .addCase(createLiquidContainer.fulfilled, (state, action) => {
        state.liquidContainerCreate.status = 'succeeded';
        state.liquidContainers = {
          ...state.liquidContainers,
          [action.payload.serialNumber]: action.payload,
        };
        state.currentLiquidContainer.liquidContainerId = action.payload.serialNumber;
        state.liquidContainerCreate.error = null;
      })
      .addCase(createLiquidContainer.rejected, (state, action) => {
        state.liquidContainerCreate.status = 'failed';
        state.liquidContainerCreate.error = action.error.message;
      });

    builder
      .addCase(updateLiquidContainer.pending, (state, action) => {
        state.liquidContainerUpdate.status = 'loading';
      })
      .addCase(updateLiquidContainer.fulfilled, (state, action) => {
        state.liquidContainerUpdate.status = 'succeeded';
        state.liquidContainers = {
          ...state.liquidContainers,
          [action.payload.serialNumber]: {
            ...state.liquidContainers[action.payload.serialNumber],
            ...action.payload,
          },
        };
        state.liquidContainerUpdate.error = null;
      })
      .addCase(updateLiquidContainer.rejected, (state, action) => {
        state.liquidContainerUpdate.status = 'failed';
        state.liquidContainerUpdate.error = action.error.message;
      });

    builder
      .addCase(fetchMaintenanceStatusForLiquidContainers.pending, (state, action) => {
        state.liquidContainersMaintenanceStatusFetch.status = 'loading';
      })
      .addCase(fetchMaintenanceStatusForLiquidContainers.fulfilled, (state, action) => {
        state.liquidContainersMaintenanceStatusFetch.status = 'succeeded';
        state.liquidContainerMaintenanceStatuses = {
          ...state.liquidContainerMaintenanceStatuses,
          ...action.payload,
        };
        state.liquidContainersMaintenanceStatusFetch.error = null;
      })
      .addCase(fetchMaintenanceStatusForLiquidContainers.rejected, (state, action) => {
        state.liquidContainersMaintenanceStatusFetch.status = 'failed';
        state.liquidContainersMaintenanceStatusFetch.error = action.error.message;
      });
  },
});

export const {
  setliquidContainers, setCurrentLiquidContainerId,
  setLiquidContainerCreateStatus, setLiquidContainerUpdateStatus, setLiquidContainerFetchStatus,
  setCreateAttachmentStatus, setRemoveAttachmentStatus,
} = liquidContainerSlice.actions;

export const selectLiquidContainers = (state) => state.liquidContainer.liquidContainers;
export const selectLiquidContainersMaintenanceStatuses = (
  state,
) => state.liquidContainer.liquidContainerMaintenanceStatuses;

export const selectCurrentLiquidContainerId = (state) => (
  state.liquidContainer.currentLiquidContainer.liquidContainerId
);

export const selectCurrentLiquidContainer = createSelector([
  selectLiquidContainers,
  selectCurrentLiquidContainerId], (liquidContainers, liquidContainerId) => {
  if (liquidContainerId == null) {
    return {};
  }
  return liquidContainers[liquidContainerId];
});

export const selectLiquidContainerStatus = (state) => state.liquidContainer.status;

export const selectAllLiquidContainerReadings = (state) => state.liquidContainer.liquidContainerReadings;

export const selectCurrentLiquidContainerReadings = createSelector([
  selectAllLiquidContainerReadings,
  selectCurrentLiquidContainerId], (liquidContainers, liquidContainerId) => {
  if (!liquidContainerId || !liquidContainers || !liquidContainers[liquidContainerId]) {
    return { summary: [], data: [], breadcrumbs: [] };
  }
  return liquidContainers[liquidContainerId];
});

export default liquidContainerSlice.reducer;
