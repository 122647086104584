import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { checkFormIsDirty } from '../../../services/form-validation';
import { convertDateToUTCDay } from '../../../services/format';
import { selectIsAssetAdmin, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { updateGasContainer } from '../../../store/slices/gas-containers-slice';
import { fetchDropdownValues } from '../../../store/slices/legacy-slice';
import BasicInput from '../../UI/molecules/BasicInput/basic-input';
import Card from '../../UI/molecules/Card/card';
import DateInput from '../../UI/molecules/DateInput/date-input';
import { gasContainerBusiness, gasContainerStyles, gasContainerTubeTypes } from './gas-container-values';

function GasContainerForm({ currentGasContainer, gasContainerStatusIsLoading }) {
  const defaultGasContainerFormValues = {
    AssetType: 'GasContainer',
    serialNumber: '',
    business: null,
    style: null,
    lastTestDate: null,
    cscdate: null,
    neckThreadDate: null,
    tareWeight: null,
    numberOfTubes: null,
    ownerCustomer: null,
    isLeased: null,
    leasedFrom: null,
    leasedToCustomer: null,
    isLeaseBack: null,
    comments: null,
    waterVolume: null,
    nominalCapacity: null,
    press110: null,
    press70: null,
    reTestDate: null,
    hoseInstallationDate: null,
    kingPinInspectionDate: null,
    aInspectionDate: null,
    bInspectionDate: null,
    lastRebuildDate: null,
    telemetryUnitId: null,
    length: null,
    isIsoFrame: null,
    isActive: null,
    tubeType: null,
    aeInspectionDate: null,
    vInspectionDate: null,
    hoseInstallationRequired: null,
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultGasContainerFormValues,
  });

  const dispatch = useDispatch();

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const [formIsDirty, setFormIsDirty] = useState(false);

  const onSubmit = (gasContainer) => {
    Object.keys(gasContainer).forEach((key) => {
      if (key.toLowerCase().includes('date') && gasContainer[key]) {
        gasContainer[key] = convertDateToUTCDay(gasContainer[key]);
      }
    });
    dispatch(updateGasContainer({ ...gasContainer, assetType: 'GasContainer' }));
    setFormIsDirty(false);
  };

  useEffect(() => {
    if (currentGasContainer) {
      Object.keys(defaultGasContainerFormValues).forEach((key) => {
        if (key.toLowerCase().includes('date') && currentGasContainer[key]) {
          setValue(key, new Date(currentGasContainer[key].replace('Z', '')));
        } else {
          setValue(key, currentGasContainer[key]);
        }
      });
    }
  }, [currentGasContainer, datePreference]);

  const basicInputProps = {
    setValue,
    watch,
    getFieldState,
    register,
    trigger,
  };

  const gasCustomers = useSelector(((state) => state.legacy?.dropdownValues?.gasCustomers))
    ?? [];
  const gasCustomersStatus = useSelector((state) => state.legacy.status);

  useEffect(() => {
    if (gasCustomersStatus === 'idle' || gasCustomersStatus === 'failed') {
      dispatch(fetchDropdownValues());
    }
  }, []);

  const sortedCustomers = useMemo(() => {
    return [...gasCustomers].filter((cust) => cust.Status === 'Active' || cust.CustomerID === currentGasContainer?.ownerCustomer?.toString())
      .sort((a, b) => a.CustomerName.trim().localeCompare(b.CustomerName.trim()));
  }, [gasCustomers, currentGasContainer]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setFormIsDirty(checkFormIsDirty(value, currentGasContainer));
    });
    return () => subscription.unsubscribe();
  }, [watch, currentGasContainer]);

  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  return (
    <>
      <Form className="overflow-auto pt-3 flex-grow-1">
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${gasContainerStatusIsLoading ? ' visible' : ' invisible'}`}
        />
        <fieldset disabled={!isAssetAdmin}>
          <Container
            fluid
            className={`${gasContainerStatusIsLoading ? ' creation-loading' : ''}`}
          >
            <Row>
              <Col>
                <Card
                  header="Info"
                  className="card-secondary card-outline"
                >
                  <Form.Group className="mb-1">
                    <Form.Label>Owner</Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('ownerCustomer', { required: 'Please select an owner.' })}
                      isInvalid={!!errors.ownerCustomer}
                    >
                      <option
                        value={null}
                        key="customer-null"
                      />
                      {sortedCustomers.map((customer, index) => (
                        <option
                          value={customer.CustomerID}
                          key={`customer-${index}`}
                        >
                          {customer.CustomerName}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                    {errors.ownerCustomer && errors.ownerCustomer.message}
                  </Form.Control.Feedback> */}
                  </Form.Group>

                  <Container className="p-0 m-0">
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Business</Form.Label>
                          <Form.Select
                            size="sm"
                            {...register('business', { required: 'Please select a business.' })}
                            isInvalid={!!errors.business}
                          >
                            {gasContainerBusiness.map((style, i) => (
                              <option
                                value={style}
                                key={style}
                              >
                                {style}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                          {errors.business && errors.business.message}
                        </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Style</Form.Label>
                          <Form.Select
                            size="sm"
                            {...register('style', { required: 'Please select a style.' })}
                            isInvalid={!!errors.style}
                          >
                            {gasContainerStyles.map((style, i) => (
                              <option
                                value={style}
                                key={style}
                              >
                                {gasContainerStyles[i]}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                          {errors.style && errors.style.message}
                        </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Tube Type</Form.Label>
                          <Form.Select
                            size="sm"
                            {...register('tubeType')}
                            isInvalid={!!errors.tubeType}
                          >
                            {gasContainerTubeTypes.map((tubeType, i) => (
                              <option
                                value={i}
                                key={tubeType}
                              >
                                {gasContainerTubeTypes[i]}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Number of Tubes</Form.Label>
                          <Form.Control
                            size="sm"
                            {...register('numberOfTubes', { required: 'Please select number of tubes.', minLength: { value: 1, message: 'Enter tubes' } })}
                            isInvalid={!!errors.numberOfTubes}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                          {errors.numberOfTubes && errors.numberOfTubes.message}
                        </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Length</Form.Label>
                          <Form.Control
                            size="sm"
                            {...register('length')}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Tare Weight</Form.Label>
                          <BasicInput
                            name="tareWeight"
                            defaultUnit="lbs"
                            options={['lbs', 'kgs']}
                            isInvalid={!!errors.tareWeight}
                            {...basicInputProps}
                            initialValue={currentGasContainer?.tareWeight}
                            valueIsLoaded={!gasContainerStatusIsLoading}
                            registerOptions={{ min: { value: 0, message: 'must be greater than zero' } }}
                          />
                          {errors.tareWeight && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.tareWeight.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Water Volume</Form.Label>
                          <BasicInput
                            name="waterVolume"
                            defaultUnit="Cubic Feet"
                            options={['Cubic Feet', 'Cubic Meters', 'Liters']}
                            isInvalid={!!errors.waterVolume}
                            {...basicInputProps}
                            initialValue={currentGasContainer?.waterVolume}
                            registerOptions={{ required: 'Please enter a valid water volume' }}
                            valueIsLoaded={!gasContainerStatusIsLoading}
                          />
                          {errors.waterVolume && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.waterVolume.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Nominal Capacity</Form.Label>
                          <Form.Control
                            size="sm"
                            {...register('nominalCapacity', { required: 'Please enter a Nominal Capacity' })}
                            isInvalid={!!errors.nominalCapacity}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                          {errors.nominalCapacity && errors.nominalCapacity.message}
                        </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Pressure 70</Form.Label>
                          <BasicInput
                            name="press70"
                            defaultUnit="psig"
                            options={['psig', 'Pa']}
                            isInvalid={!!errors.press70}
                            {...basicInputProps}
                            initialValue={currentGasContainer?.press70}
                            registerOptions={{ required: 'Please enter a Pressure 70' }}
                            valueIsLoaded={!gasContainerStatusIsLoading}
                          />
                          {errors.press70 && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.press70.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Pressure 110</Form.Label>
                          <BasicInput
                            name="press110"
                            defaultUnit="psig"
                            options={['psig', 'Pa']}
                            isInvalid={!!errors.press110}
                            {...basicInputProps}
                            initialValue={currentGasContainer?.press110}
                            registerOptions={{ required: 'Please enter a Pressure 110' }}
                            valueIsLoaded={!gasContainerStatusIsLoading}
                          />
                          {errors.press110 && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.press110.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group>
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        size="sm"
                        as="textarea"
                        rows={3}
                        {...register('comments')}
                        style={{ resize: 'none' }}
                      />
                    </Form.Group>

                  </Container>

                </Card>
                <Card
                  header="Status"
                  className="card-secondary card-outline"
                >
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        {/* <Form.Label>Is Active</Form.Label> */}
                        <Form.Check
                          {...register('isActive')}
                          type="switch"
                          inline
                          id="isActive-switch"
                          label="Active"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Check
                          {...register('isIsoFrame')}
                          type="switch"
                          inline
                          id="isActive-switch"
                          label="ISO Frame"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col>
                <Card
                  header="Lease Information"
                  className="card-secondary card-outline"
                >
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Check
                          {...register('isLeased')}
                          type="switch"
                          inline
                          id="isActive-switch"
                          label="Leased"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Check
                        {...register('isLeaseBack')}
                        type="switch"
                        inline
                        id="isActive-switch"
                        label="Leased Back"
                      />
                    </Col>
                  </Row>
                  <Form.Group className="mb-1">
                    <Form.Label>Leased To</Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('leasedToCustomer')}
                    >
                      {gasCustomers.map((customer) => (
                        <option
                          value={customer.CustomerID}
                          key={customer.CustomerID}
                        >
                          {customer.CustomerName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Leased From</Form.Label>
                    <Form.Control
                      size="sm"
                      {...register('leasedFrom')}
                    />
                  </Form.Group>
                </Card>

                <Card
                  header="Maintenance"
                  className="card-secondary card-outline"
                >
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last CSC Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="cscdate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last Neck Thread Inspection Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="neckThreadDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last A Inspection</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="aInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last DOT Inspection</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="bInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last AE Inspection Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="aeInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last Visual Inspection Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="vInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>King Pin Inspection Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="kingPinInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Last Rebuild</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="lastRebuildDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>IoT Device ID</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('telemetryUnitId')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Hose A Installation</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="hoseInstallationDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Check
                          {...register('hoseInstallationRequired')}
                          type="switch"
                          inline
                          id="isActive-switch"
                          label="Hose Installation Required"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </fieldset>
      </Form>
      <div className="d-flex flex-row-reverse position-sticky bottom-0 p-2 pr-1 bg-body border-top">
        {isAssetAdmin && (
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={gasContainerStatusIsLoading || !formIsDirty}
          >
            Update
          </Button>
        )}
      </div>
    </>
  );
}

export default GasContainerForm;
