import axios from 'axios';

export const memoizePromiseFn = (fn) => {
  const cache = new Map();

  return (...args) => {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      return cache.get(key);
    }

    cache.set(key, fn(...args).catch((error) => {
      // Delete cache entry if API call fails
      cache.delete(key);
      return Promise.reject(error);
    }));

    return cache.get(key);
  };
};
export const handleRequestError = (error) => {
  const errorlog = `ERROR: ${error}`;
  throw error.response.data;
};

export const getRareGasCustomers = async () => {
  const results = await axios.get(`/Customer/getRareGasCustomers`).catch(handleRequestError);
  return results.data;
};
export const getElectronics = async () => {
  const results = await axios.get(`/RareGas/GetElectronics`).catch(handleRequestError);
  return results.data;
};
export const updateElectronics = async (asset) => {
  const results = await axios.put(`/RareGas/UpdateElectronics`, asset).catch(handleRequestError);
  return results.data;
};
export const insertElectronics = async (asset) => {
  const results = await axios.post(`/RareGas/InsertElectronics/`, asset).catch(handleRequestError);
  return results.data;
};


//getC2CChecklist
//createDewarRentalInvoice
//getDewarRental




export const getC2CChecklist = async (workOrderId) => {
  const results = await axios.get(`/Maintenance/getC2CChecklist/${workOrderId}`).catch(handleRequestError);
  return results.data;
};
export const upsertC2CChecklist = async (checklist) => {
  const results = await axios.post(`/Maintenance/upsertC2CChecklist`, checklist).catch(handleRequestError);
  return results.data;
};

export const getMaintenanceInfo = async (ids) => {
  const results = await axios.post(`/Maintenance/MaintenanceRequests/GetMaintenanceInfo`, ids).catch(handleRequestError);
  return results.data;
};

//GetMaintenanceInfo
export const getLateReadings = async (queryDefinition) => {
  const results = await axios.post(`/Maintenance/getLateReadings`, queryDefinition).catch(handleRequestError);
  return results.data;
};

export const getRareGasModules = async () => {
  const results = await axios.get(`/RareGas/getRareGasModules`).catch(handleRequestError);
  return results.data;
};

export const CreateSnapshot = async (year) => {
  const results = await axios.post('/DemandForecasting/CreateSnapshot', { year }).catch(handleRequestError);
  return results.data;
}
export const InsertLiquidCustomerGroupings = async (grouping) => {
  const results = await axios.post('/DemandForecasting/InsertLiquidCustomerGroupings', grouping).catch(handleRequestError);
  return results.data;
}
export const UpdateLiquidCustomerGroupings = async (grouping) => {
  const results = await axios.put('/DemandForecasting/UpdateLiquidCustomerGroupings', grouping).catch(handleRequestError);
  return results.data;
}
export const GetLiquidCustomerGroupings = async (grouping) => {
  const results = await axios.get('/DemandForecasting/GetLiquidCustomerGroupings', grouping).catch(handleRequestError);
  return results.data;
}
export const exportDemandForecasting = async (forecasting, filename) => {
  const results = await axios.post('/DemandForecasting/ExportDemandForecasting', forecasting, { responseType: 'blob' }).catch(handleRequestError);

  const url = window.URL.createObjectURL(new Blob([results.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};
export const saveDemandForecasting = async (forecasting) => {
  const results = await axios.post('/DemandForecasting/SaveDemandForecasting', forecasting).catch(handleRequestError);
  return results.data;
};
export const getLiquidShipments = async (year) => {
  const results = await axios.get(`/DemandForecasting/GetLiquidShipments/${year}`).catch(handleRequestError);
  return results.data;
}

//AddGasCustomerToRTU
export const AddGasCustomerToRTU = async (customerId) => {
  const results = await axios.post('/Telemetry/AddGasCustomerToRTU', customerId).catch(handleRequestError);
  return results.data;
}
export const getUsageMetrics = async () => {
  const results = await axios.get('/Telemetry/getUsageMetrics').catch(handleRequestError);
  return results.data;
}

export const updateRTU = async (rtu) => {
  const results = await axios.put('/Telemetry/UpdateRTU', rtu).catch(handleRequestError);
  return results.data;
};
export const GetRTUDetailsDTO = async (myArgs) => {
  const args = myArgs.split('_')
  let range = null;
  const { rtuId, channelId, days, allReadings } = { rtuId: args[0], channelId: args[1], days: args[2], allReadings: args[3] };
  const results = await axios.get(`/Telemetry/GetRTUDetailsDTO/${rtuId}/${channelId}/${days}/${allReadings}`).catch(handleRequestError);
  return results.data;
};
export const getRTUDetails = async (rtuId, channelId, days, allReadings) => {
  const results = await axios.get(`/Telemetry/getRTUDetails/${rtuId}/${channelId}/${days}/${allReadings}`).catch(handleRequestError);
  return results.data;
};
export const getRTUList = async () => {
  const results = await axios.get('/Telemetry/getRTUList').catch(handleRequestError);
  return results.data;
};
export const getRTU = async (pk) => {
  const results = await axios.get(`/Telemetry/getRTU/${pk}`).catch(handleRequestError);
  return results.data;
};


//RefreshDewarCustomers
export const refreshDewarCustomers = async () => {
  const results = await axios.post(`/Customer/refreshDewarCustomers`).catch(handleRequestError);
  return results.data;
};

export const getAnalyzerComponentsReq = async () => {
  const results = await axios.get(`/Location/GetAnalyzerComponents`).catch(handleRequestError);
  return results.data;
};

//getAnalyzerComponents
export const getAnalyzersReq = async () => {
  const results = await axios.get(`/Location/GetAnalyzers`, {
    headers: {
      'Cache-Control': 'max-age=1000, public'
    }
  }).catch(handleRequestError);
  return results.data;
};
export const getAnalyzerComponents = memoizePromiseFn(getAnalyzerComponentsReq);
export const getAnalyzers = memoizePromiseFn(getAnalyzersReq);


//undoShipment
export const undoShipment = async (shipmentId) => {
  const results = await axios.put(`/Maintenance/UndoShipment/${shipmentId}`).catch(handleRequestError);
  return results.data;
};

//getUndoShipmentPreview
export const getUndoShipmentPreview = async (shipmentId) => {
  const results = await axios.get(`/Maintenance/GetUndoShipmentPreview/${shipmentId}`).catch(handleRequestError);
  return results.data;
};

export const insertLocation = async (location) => {
  const results = await axios.post('/Location/InsertLocation', location).catch(handleRequestError);
  return results.data;
};

export const getLocations = async (assetId) => {
  const results = await axios.get('/Location/GetLocations').catch(handleRequestError);
  return results.data;
};
export const updateLocation = async (location) => {
  const results = await axios.put('/Location/UpdateLocation', location).catch(handleRequestError);
  return results.data;
};

//UpdateLocation

export const getGasHistory = async (assetId) => {
  const results = await axios.get(`/AssetManagement/GetGasHistory/${assetId}`).catch(handleRequestError);
  return results.data;
};
export const getChassisHistory = async (assetId) => {
  const results = await axios.get(`/AssetManagement/GetChassisHistory/${assetId}`).catch(handleRequestError);
  return results.data;
};
export const getDewarTransactionHistory = async (assetId) => {
  const results = await axios.get(`/AssetManagement/GetDewarTransactionHistory/${assetId}`).catch(handleRequestError);
  return results.data;
};
export const getLiquidContainerHistory = async (assetId) => {
  const results = await axios.get(`/AssetManagement/GetLiquidContainerHistory/${assetId}`).catch(handleRequestError);
  return results.data;
};
export const getCustomerLiquidContainerHistory = async (customerId) => {
  const results = await axios.get(`/Customer/LiquidCustomerHistory/${customerId}`).catch(handleRequestError);
  return results.data;
};
export const getCustomerGasContainerHistory = async (customerId) => {
  const results = await axios.get(`/Customer/GasCustomerHistory/${customerId}`).catch(handleRequestError);
  return results.data;
};
export const getCustomerPackagedContainerHistory = async (customerId) => {
  const results = await axios.get(`/Customer/PackagedCustomerHistory/${customerId}`).catch(handleRequestError);
  return results.data;
};
export const logUserVisit = async () => {
  await axios.post('/Administration/LogUserVisit').catch(handleRequestError);
};
export const updateChassidId = async (oldSerialNumber, newSerialNumber) => {
  await axios.put(`/AssetManagement/UpdateSerialNumber/Chassis/${oldSerialNumber}/${newSerialNumber}`).catch(handleRequestError);
};
export const updateLiquidContainerId = async (oldLiquidContainerId, newLiquidContainerId) => {
  await axios.put(`/AssetManagement/UpdateContainerId/Liquid/${oldLiquidContainerId}/${newLiquidContainerId}`).catch(handleRequestError);
};
export const updateGasContainerId = async (oldGasContainerId, newLiquidContainerId) => {
  await axios.put(`/AssetManagement/UpdateContainerId/Gas/${oldGasContainerId}/${newLiquidContainerId}`).catch(handleRequestError);
};
export const updatePackageProduct = async (oldSerialNumber, newSerialNumber) => {
  await axios.put(`/AssetManagement/UpdatePackageProduct/${oldSerialNumber}/${newSerialNumber}`).catch(handleRequestError);
};

export const GetLiquidCustomers = async (queryDefinition) => {
  const results = await axios.get('/Customer/LiquidCustomers').catch(handleRequestError);
  return results.data;
};
export const GetLiquidCustomer = async (customerId) => {
  const results = await axios.get(`/Customer/LiquidCustomer/${customerId}`).catch(handleRequestError);
  return results.data;
};
export const GetGasCustomers = async (queryDefinition) => {
  const results = await axios.get('/Customer/GasCustomers').catch(handleRequestError);
  return results.data;
};
export const UpdateLiquidCustomer = async (customer) => {
  const results = await axios.put(`/Customer/LiquidCustomer/${customer.customerId}`, customer).catch(handleRequestError);
  return results.data;
};
export const InsertLiquidCustomer = async (customer) => {
  const results = await axios.post('/Customer/InsertLiquidCustomer', customer).catch(handleRequestError);
  return results.data;
};
export const InsertGasCustomer = async (customer) => {
  const results = await axios.post('/Customer/InsertGasCustomer', customer).catch(handleRequestError);
  return results.data;
};
export const UpdateGasCustomer = async (customer) => {
  const results = await axios.put(`/Customer/GasCustomer/${customer.customerId}`, customer).catch(handleRequestError);
  return results.data;
};
export const UpdateDewarCustomer = async (customer) => {
  const results = await axios.put(`/Customer/DewarCustomer/${customer.site}`, customer).catch(handleRequestError);
  return results.data;
};
//MultiAttachment
export const getEntityAttachmentsMulti = async (reqs) => {
  const results = await axios.post(`/FileAttachments/MultiAttachment/`, { AttachmetnRequests: reqs });
  return results.data;
};
export const getEntityAttachmentsObj = async ({ entityTypeId, entityPK }) => {
  const results = await axios.get(`/FileAttachments/Attachment/${entityTypeId}/${entityPK}`);
  return results.data;
};
export const GetDewarCustomers = async (queryDefinition) => {
  const results = await axios.post('/Customer/Dewar', queryDefinition).catch(handleRequestError);
  return results.data;
};
export const GetCustomers = async () => {
  const results = await axios.get('/AssetManagement/GetCustomers').catch(handleRequestError);
  return results.data;
};
export const GetWorldRareGasReadings = async () => {
  const results = await axios.get('/AssetManagement/RareGasWorldReadings').catch(handleRequestError);
  return results.data;
};
export const GetWorldReadings = async () => {
  const results = await axios.get('/AssetManagement/WorldReadings').catch(handleRequestError);
  return results.data;
};
export const DeleteVendor = async (vendorId) => {
  const results = await axios.delete(`/Maintenance/VendorDelete${vendorId}`).catch(handleRequestError);
  return results.data;
};

export const updateUserRequestObj = async (user) => {
  const results = await axios.put(`/Administration/Users/${user.userId}`, user).catch(handleRequestError);
  return results.data;
};

export const UpdateVendor = async (vendor) => {
  const results = await axios.put(`/Maintenance/VendorUpdate/${vendor.vendorId}`, vendor).catch(handleRequestError);
  return results.data;
};
export const CreateVendor = async (vendor) => {
  const results = await axios.post('/Maintenance/VendorCreate', vendor).catch(handleRequestError);
  return results.data;
};
export const GetVendorList = async () => {
  const results = await axios.get('/Maintenance/Vendor').catch(handleRequestError);
  return results.data;
};

export const getContainerSummaryData = async (type, serialNumber) => {
  const results = await axios.get(`/AssetManagement/ReadingSummary/${type}/${serialNumber}`).catch(handleRequestError);
  return results.data;
};
export const getGasAndChassisReadingsObj = async (argString) => {
  const args = argString.split('_')
  let range = null;
  if (args.length == 4) {
    range = {
      start: args[2],
      end: args[3]
    }
  }
  return await getGasAndChassisReadings(args[0], args[1], range);
};
export const getGasAndChassisReadings = async (type, serialNumber, range) => {
  if (!range) {
    const d = new Date();
    d.setMonth(d.getMonth() - 6);
    range = {
      start: d.toJSON(),
      end: (new Date()).toJSON(),
    };
  }
  const results = await axios.get(`/AssetManagement/ReadingDataGasAndChassis/${type}/${serialNumber}/${range.start}/${range.end}`).catch(handleRequestError);
  return results.data;
};
export const getContainerReadings = async (type, serialNumber, range) => {
  if (!range) {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    range = {
      start: d.toJSON(),
      end: (new Date()).toJSON(),
    };
  }
  const results = await axios.get(`/AssetManagement/ReadingData/LiquidContainer/${serialNumber}/${range?.start}/${range?.end}`).catch(handleRequestError);
  return results.data;
};
export const getAssetType = async (type) => {
  const results = await axios.get(`/AssetManagement/Assets/${type}`).catch(handleRequestError);
  return results.data;
};

export const getChassis = async () => {
  const results = await getAssetType('Chassis');
  return results;
};

export const getOneChassis = async (serialNumber) => {
  const results = await axios.get(`/AssetManagement/Assets/Chassis/${serialNumber}`).catch(handleRequestError);
  return results.data;
};

export const getPackagedProducts = async () => {
  const results = await getAssetType('PackagedProduct');
  return results;
};

export const getPackagedProduct = async (serialNumber) => {
  const results = await axios.get(`/AssetManagement/Assets/PackagedProduct/${serialNumber}`).catch(handleRequestError);
  return results.data;
};

export const getPackagedProductBySerialNumber = async (serialNumber) => {
  const results = await axios.get(`/AssetManagement/AssetsBySerialNumber/PackagedProduct/${serialNumber}`).catch(handleRequestError);
  return results.data;
};

export const createPackagedProductRequest = async (packagedProduct) => {
  const results = await axios.post('/AssetManagement/Assets/PackagedProduct', packagedProduct).catch(handleRequestError);
  return results.data;
};

export const updatePackagedProductRequest = async (packagedProduct) => {
  const results = await axios.put(`/AssetManagement/Assets/PackagedProduct/${packagedProduct.id}`, packagedProduct).catch(handleRequestError);
  return results.data;
};


export const getLiquidContainers = async () => {
  const results = await getAssetType('LiquidContainer');
  return results;
};
export const getLiquidContainersMemoize = memoizePromiseFn(getLiquidContainers);
export const getLiquidContainer = async (serialNumber) => {
  const results = await axios.get(`/AssetManagement/Assets/LiquidContainer/${serialNumber}`).catch(handleRequestError);
  return results.data;
};

export const createLiquidContainerRequest = async (liquidContainer) => {
  const results = await axios.post('/AssetManagement/Assets/LiquidContainer', liquidContainer).catch(handleRequestError);
  return results.data;
};

export const updateLiquidContainerRequest = async (liquidContainer) => {
  const results = await axios.put(`/AssetManagement/Assets/LiquidContainer/${liquidContainer.serialNumber}`, liquidContainer).catch(handleRequestError);
  return results.data;
};

export const getGasContainers = async () => {
  const results = await getAssetType('GasContainer');
  return results;
};

export const getGasContainer = async (serialNumber) => {
  const results = await axios.get(`/AssetManagement/Assets/GasContainer/${serialNumber}`).catch(handleRequestError);
  return results.data;
};

export const createGasContainerRequest = async (gasContainer) => {
  const results = await axios.post('/AssetManagement/Assets/GasContainer', gasContainer).catch(handleRequestError);
  return results.data;
};

export const updateGasContainerRequest = async (gasContainer) => {
  const results = await axios.put(`/AssetManagement/Assets/GasContainer/${gasContainer.serialNumber}`, gasContainer).catch(handleRequestError);
  return results.data;
};

export const createChassisRequest = async (chassis) => {
  const results = await axios.post('/AssetManagement/Assets/Chassis', chassis).catch(handleRequestError);
  return results.data;
};

export const updateChassisRequest = async (chassis) => {
  const results = await axios.put(`/AssetManagement/Assets/Chassis/${chassis.serialNumber}`, chassis).catch(handleRequestError);
  return results.data;
};

export const getDropdownValues = async () => {
  const results = await axios.get('/AssetManagement/DropdownValues').catch(handleRequestError);
  return results.data;
};
export const getDropdownValuesMemoized = memoizePromiseFn(getDropdownValues);

export const getCurrentAssetLocations = async () => {
  const results = await axios.get('/AssetManagement/CurrentAssetLocations').catch(handleRequestError);
  return results.data;
};

export const getAssetOrderHistoryLogs = async (type, serialNumber) => {
  const results = await axios.get(`/AssetManagement/Assets/${type}/OrderHistoryLogs/${serialNumber}`).catch(handleRequestError);
  return results.data;
};

export const getAssetAttachment = async (attachmentId, fileName) => {
  const results = await axios.get(`/AssetManagement/Attachment/${attachmentId}`, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch(handleRequestError);
  return (results as any).data;
};

export const getFileAttachmentDropdownValues = async () => {
  const results = await axios.get('/FileAttachments/DropdownValues').catch(handleRequestError);
  return results.data;
};

export const deleteLiquidAttachment = async (attachmentId) => {
  const results = await axios.delete(`/AssetManagement/Attachment/${attachmentId}`);
  return results.data;
};

export const deletePartDetailsAttachment = async (attachmentId) => {
  const results = await axios.delete(`/FileAttachments/Attachment/${attachmentId}`);
  return results.data;
};

export const deleteGasAttachment = async (attachmentId) => {
  const results = await axios.delete(`/AssetManagement/Attachment/${attachmentId}`);
  return results.data;
};

export const deletePackagedProductAttachment = async (attachmentId) => {
  const results = await axios.delete(`/AssetManagement/Attachment/${attachmentId}`);
  return results.data;
};

export const deleteChassisAttachment = async (attachmentId) => {
  const results = await axios.delete(`/AssetManagement/Attachment/${attachmentId}`);
  return results.data;
};

export const deletePartAttachment = async (attachmentId) => {
  const results = await axios.delete(`/FileAttachments/Attachment/${attachmentId}`);
  return results.data;
};

export const getAttachment = async (attachmentId, fileName) => {
  const results = await axios.get(`/FileAttachments/Attachment/${attachmentId}`, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch(handleRequestError);
  return (results as any).data;
};
export const getAttachmentObj = async ({ attachmentId, fileName }) => {
  return getAttachment(attachmentId, fileName);
};
export const deleteAttachmentObj = async (attachment) => {
  const results = await axios.delete(`/FileAttachments/Attachment/${attachment.attachmentId}`);
  return results.data;
};
export const deleteAttachment = async (attachmentId) => {
  const results = await axios.delete(`/FileAttachments/Attachment/${attachmentId}`);
  return results.data;
};

export const getEntityAttachments = async (entityTypeId, entityPK) => {
  const results = await axios.get(`/FileAttachments/Attachment/${entityTypeId}/${entityPK}`);
  return results.data;
};

export const getAttachmentsBulk = async (entityTypeId, entityPK, archiveName) => {
  const results = await axios.get(`/FileAttachments/Attachment/Zipped/${entityTypeId}/${entityPK}`, { responseType: 'application/zip' } as any)
    .then((response) => {
      // const url = window.URL.createObjectURL(response.data, { type: 'octet/stream' });
      const link = document.createElement('a');
      link.href = response.data;
      // link.setAttribute('href', '');
      link.setAttribute('download', `${archiveName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(handleRequestError);
  return (results as any).data;
};

export const createAttachmentRequest = async (attachment) => {
  const results = await axios.post(
    '/FileAttachments/Attachment',
    attachment,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const deleteAttachmentRequest = async (attachmentId) => {
  const results = await axios.delete(`/AssetManagement/Attachment/${attachmentId}`);
  return results.data;
};

export const createLiquidAttachmentRequest = async (attachment) => {
  const results = await axios.post(
    '/AssetManagement/Attachment',
    attachment,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const createPartDetailsAttachmentRequest = async (attachment) => {
  const results = await axios.post(
    '/FileAttachments/Attachment',
    attachment,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const createGasAttachmentRequest = async (attachment) => {
  const results = await axios.post(
    '/AssetManagement/Attachment',
    attachment,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const createPackagedProductAttachmentRequest = async (attachment) => {
  const results = await axios.post(
    '/AssetManagement/Attachment',
    attachment,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const createChassisAttachmentRequest = async (attachment) => {
  const results = await axios.post(
    '/AssetManagement/Attachment',
    attachment,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const createPartAttachmentRequest = async (attachment) => {
  const results = await axios.post(
    '/FileAttachments/Attachment',
    attachment,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const getPurchaseRequestCost = async (purchaseRequestIds) => {
  const results = await axios.post('/Maintenance/PurchaseRequestCost', purchaseRequestIds).catch(handleRequestError);
  return results.data;
};

export const getAdUsers = async (searchValue) => {
  const results = await axios.get(`/Administration/AdUsers?searchValue=${searchValue}`).catch(handleRequestError);
  return results.data;
};

export const getUsers = async () => {
  const results = await axios.get('/Administration/Users').catch(handleRequestError);
  return results.data;
};

export const createUserRequest = async (user) => {
  const results = await axios.post('/Administration/Users', user).catch(handleRequestError);
  return results.data;
};

export const createExternalUserRequest = async (user) => {
  const results = await axios.post('/Administration/ExternalUsers', user).catch(handleRequestError);
  return results.data;
};

export const getUser = async (userId) => {
  const results = await axios.get(`/Administration/Users/${userId}`).catch(handleRequestError);
  return results.data;
};

export const updateUserRequest = async (userId, user) => {
  const results = await axios.put(`/Administration/Users/${userId}`, user).catch(handleRequestError);
  return results.data;
};

export const deactivateUserRequest = async (userId) => {
  const results = await axios.delete(`/Administration/Users/${userId}`).catch(handleRequestError);
  return results.data;
};

export const getUserByAdId = async (adId) => {
  const results = await axios.get(`/Administration/Users/ByAdId/${adId}`).catch(handleRequestError);
  return results.data;
};

export const addUserRole = async (userId, roleId) => {
  const results = await axios.post('/Administration/UserRoles', { userId, roleId }).catch(handleRequestError);
  return results.data;
};

export const removeUserRole = async (userId, roleId) => {
  const results = await axios.post('/Administration/UserRoles/Remove', { userId, roleId }).catch(handleRequestError);
  return results.data;
};

export const createRoleRequest = async (role) => {
  const results = await axios.post('/Administration/Roles', role).catch(handleRequestError);
  return results.data;
};

export const updateRoleRequest = async (roleId, role) => {
  const results = await axios.put(`/Administration/Roles/${roleId}`, role).catch(handleRequestError);
  return results.data;
};

export const deleteRoleRequest = async (roleId) => {
  const results = await axios.delete(`/Administration/Roles/${roleId}`).catch(handleRequestError);
  return results.data;
};

export const createPermissionRequest = async (permission) => {
  const results = await axios.post('/Administration/Permissions', permission).catch(handleRequestError);
  return results.data;
};

export const getUserLocations = async () => {
  const results = await axios.get('/Administration/UserLocations').catch(handleRequestError);
  return results.data;
};

export const addUserLocation = async (locationId, userId) => {
  const results = await axios.post('/Administration/UserLocations', { locationId, userId }).catch(handleRequestError);
  return results.data;
};

export const RemoveUserLocation = async (locationId, userId) => {
  const results = await axios.post('/Administration/UserLocations/Remove', { locationId, userId }).catch(handleRequestError);
  return results.data;
};

export const getRoles = async () => {
  const results = await axios.get('/Administration/Roles').catch(handleRequestError);
  return results.data;
};

export const getRole = async (roleId) => {
  const results = await axios.get(`/Administration/Roles/${roleId}`).catch(handleRequestError);
  return results.data;
};

export const addRolePermission = async (roleId, permissionId) => {
  const results = await axios.post('/Administration/RolePermissions', { roleId, permissionId }).catch(handleRequestError);
  return results.data;
};

export const removeRolePermission = async (roleId, permissionId) => {
  const results = await axios.post('/Administration/RolePermissions/Remove', { roleId, permissionId }).catch(handleRequestError);
  return results.data;
};

export const getPermissions = async () => {
  const results = await axios.get('/Administration/Permissions').catch(handleRequestError);
  return results.data;
};

export const getWorkOrder = async (workOrderId) => {
  const results = await axios.get(`/Maintenance/WorkOrders/${workOrderId}`).catch(handleRequestError);
  return results.data;
};

export const getWorkOrders = async () => {
  const results = await axios.get('/Maintenance/WorkOrders').catch(handleRequestError);
  return results.data;
};

export const getWorkOrdersForAsset = async ({ assetType, assetSerialNumber }) => {
  const results = await axios.get(`/Maintenance/WorkOrders?assetType=${assetType}&assetSerialNumber=${assetSerialNumber}`).catch(handleRequestError);
  return results.data;
};

export const createWorkOrderRequest = async (workOrder) => {
  const results = await axios.post('/Maintenance/WorkOrders', workOrder).catch(handleRequestError);
  return results.data;
};

export const updateWorkOrderRequest = async (workOrder) => {
  const results = await axios.put(`/Maintenance/WorkOrders/${workOrder.workOrderId}`, workOrder).catch(handleRequestError);
  return results.data;
};

export const putWorkOrderStatus = async (workOrderId, workOrderStatusId) => {
  const results = await axios.put(`/Maintenance/WorkOrderStatus/${workOrderId}`, { workOrderStatusId }).catch(handleRequestError);
  return results.data;
};

export const getMaintenanceRequests = async () => {
  const results = await axios.get('/Maintenance/MaintenanceRequests').catch(handleRequestError);
  return results.data;
};

export const getPendingMaintenanceRequests = async () => {
  const results = await axios.get('/Maintenance/MaintenanceRequests/Pending').catch(handleRequestError);
  return results.data;
};

export const getMaintenanceRequestsForAssetList = async (assetTypeId, assetIds) => {
  const results = await axios.post(
    `/Maintenance/MaintenanceRequests/ForAssetList/${assetTypeId}`,
    assetIds,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const getMaintenanceRequestsForAsset = async ({ assetType, assetSerialNumber }) => {
  const results = await axios.get(`/Maintenance/MaintenanceRequests?assetType=${assetType}&assetSerialNumber=${assetSerialNumber}`).catch(handleRequestError);
  return results.data;
};

export const postMaintenanceRequest = async (maintenanceRequest) => {
  const results = await axios.post('/Maintenance/MaintenanceRequests', maintenanceRequest).catch(handleRequestError);
  return results.data;
};

export const postMaintenanceRequestLineItem = async (maintenanceRequestId, lineItem) => {
  const results = await axios.post(`/Maintenance/MaintenanceRequests/${maintenanceRequestId}/LineItems`, lineItem).catch(handleRequestError);
  return results.data;
};

export const postMaintenanceRequestWithWorkOrder = async (maintenanceRequest) => {
  const results = await axios.post('/Maintenance/MaintenanceRequestWithWorkOrder', maintenanceRequest).catch(handleRequestError);
  return results.data;
};

export const postBulkMaintenanceRequest = async (maintenanceRequest) => {
  const results = await axios.post('/Maintenance/BulkMaintenanceRequests', maintenanceRequest).catch(handleRequestError);
  return results.data;
};

export const postWorkOrderLineItem = async (workOrderId, lineItem) => {
  const results = await axios.post(`/Maintenance/WorkOrders/${workOrderId}/LineItems`, lineItem).catch(handleRequestError);
  return results.data;
};

export const deleteMaintenanceRequestLineItemRequest = async (maintenanceRequestId, lineItemId) => {
  const results = await axios.delete(`/Maintenance/MaintenanceRequests/${maintenanceRequestId}/LineItems/${lineItemId}`).catch(handleRequestError);
  return results.data;
};

export const completeMaintenanceRequestLineItemRequest = async (
  maintenanceRequestId,
  lineItem,
) => {
  const results = await axios.put(`/Maintenance/MaintenanceRequests/${maintenanceRequestId}/LineItemsStatus/${lineItem.maintenanceRequestLineItemId}`, lineItem).catch(handleRequestError);
  return results.data;
};

export const updateMaintenanceRequestLineItemRequest = async (
  maintenanceRequestId,
  lineItem,
) => {
  const results = await axios.put(`/Maintenance/MaintenanceRequests/${maintenanceRequestId}/LineItems/${lineItem.maintenanceRequestLineItemId}`, lineItem).catch(handleRequestError);
  return results.data;
};

export const undoMaintenanceRequestLineItemRequest = async (
  maintenanceRequestId,
  lineItem,
) => {
  const results = await axios.put(`/Maintenance/MaintenanceRequests/${maintenanceRequestId}/UndoLineItem/${lineItem.maintenanceRequestLineItemId}`, lineItem).catch(handleRequestError);
  return results.data;
};

export const bulkUpdateMaintenanceRequestLineItemsRequest = async (
  maintenanceRequestId,
  lineItems,
) => {
  const results = await axios.put(`/Maintenance/MaintenanceRequests/${maintenanceRequestId}/LineItems`, lineItems).catch(handleRequestError);
  return results.data;
};

export const bulkUpdateAndCompleteMaintenanceRequestLineItemsRequest = async (
  maintenanceRequestId,
  lineItems,
) => {
  const results = await axios.put(`/Maintenance/MaintenanceRequests/${maintenanceRequestId}/LineItemsComplete`, lineItems).catch(handleRequestError);
  return results.data;
};

export const putMaintenanceRequest = async (maintenanceRequest) => {
  const results = await axios.put(
    `/Maintenance/MaintenanceRequests/${maintenanceRequest.maintenanceRequestId}`,
    maintenanceRequest,
  ).catch(handleRequestError);
  return results.data;
};

export const assignMaintenanceRequestToWorkOrder = async (request) => {
  const results = await axios.put('/Maintenance/MaintenanceRequests/AssignToWorkOrder', request).catch(handleRequestError);
  return results.data;
};

export const workOrderAssignMaintenanceRequest = async (request) => {
  const results = await axios.put('/Maintenance/MaintenanceRequests/WorkOrderAssignMaintenanceRequest', request).catch(handleRequestError);
  return results.data;
};

export const unassignMaintenanceRequestFromWorkOrder = async (request) => {
  const results = await axios.put('/Maintenance/MaintenanceRequests/UnassignFromWorkOrder', request).catch(handleRequestError);
  return results.data;
};

export const getMaintenanceRequestTypes = async () => {
  const results = await axios.get('/Maintenance/MaintenanceRequestTypes').catch(handleRequestError);
  return results.data;
};
export const getMaintenanceRequestTypesMemoized = memoizePromiseFn(getMaintenanceRequestTypes);

export const postMaintenanceRequestType = async (maintenanceRequest) => {
  const results = await axios.post('/Maintenance/MaintenanceRequestTypes', maintenanceRequest).catch(handleRequestError);
  return results.data;
};

export const getMaintenanceStatusForAssets = async (assetTypeId, assetIds) => {
  if (assetIds.length == 0) return [];
  const results = await axios.post(
    `/Maintenance/MaintenanceRequests/AssetStatus/${assetTypeId}`,
    assetIds,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).catch(handleRequestError);
  return results.data;
};

export const putMaintenanceRequestType = async (maintenanceRequestType) => {
  const results = await axios.put(
    `/Maintenance/MaintenanceRequestTypes/${maintenanceRequestType.maintenanceRequestTypeId}`,
    maintenanceRequestType,
  ).catch(handleRequestError);
  return results.data;
};

export const deleteMaintenanceRequestType = async (maintenanceRequestTypeId) => {
  const results = await axios.delete(`/Maintenance/MaintenanceRequestTypes/${maintenanceRequestTypeId}`).catch(handleRequestError);
  return results.data;
};

export const getMaintenanceRequestTypeTemplate = async (maintenanceRequestTypeTemplateId) => {
  const results = await axios.get(`/Maintenance/MaintenanceRequestTypeTemplate/${maintenanceRequestTypeTemplateId}`).catch(handleRequestError);
  return results.data;
};

export const postMaintenanceRequestTypeTemplate = async (maintenanceRequestTypeTemplate) => {
  const results = await axios.post('/Maintenance/MaintenanceRequestTypeTemplate', maintenanceRequestTypeTemplate).catch(handleRequestError);
  return results.data;
};

export const putMaintenanceRequestTypeTemplate = async (maintenanceRequestTypeTemplate) => {
  const results = await axios.put(
    `/Maintenance/MaintenanceRequestTypeTemplate/${maintenanceRequestTypeTemplate.maintenanceRequestTypeTemplateId}`,
    maintenanceRequestTypeTemplate,
  ).catch(handleRequestError);
  return results.data;
};

export const deleteMaintenanceRequestTypeTemplate = async (maintenanceRequestTypeTemplateId) => {
  const results = await axios.delete(`/Maintenance/MaintenanceRequestTypeTemplate/${maintenanceRequestTypeTemplateId}`).catch(handleRequestError);
  return results.data;
};

// export const getMaintenanceRequestLineItemTemplate = async () => {
// eslint-disable-next-line max-len
//   const results = await axios.get('/Maintenance/MaintenanceRequestTypes').catch(handleRequestError);
//   return results.data;
// };

// eslint-disable-next-line max-len
export const postMaintenanceRequestLineItemTemplate = async (maintenanceRequestLineItemTemplate) => {
  const results = await axios.post('/Maintenance/MaintenanceRequestLineItemTemplate', maintenanceRequestLineItemTemplate).catch(handleRequestError);
  return results.data;
};

export const putMaintenanceRequestLineItemTemplate = async (maintenanceRequestLineItemTemplate) => {
  const results = await axios.put(
    `/Maintenance/MaintenanceRequestLineItemTemplate/${maintenanceRequestLineItemTemplate.maintenanceRequestLineItemTemplateId}`,
    maintenanceRequestLineItemTemplate,
  ).catch(handleRequestError);
  return results.data;
};

// eslint-disable-next-line max-len
export const deleteMaintenanceRequestLineItemTemplate = async (maintenanceRequestLineItemTemplateId) => {
  const results = await axios.delete(`/Maintenance/MaintenanceRequestLineItemTemplate/${maintenanceRequestLineItemTemplateId}`).catch(handleRequestError);
  return results.data;
};

export const getUserAuditLogs = async (OId) => {
  const results = await axios.get(`/Security/AuditLogs/${OId}`).catch(handleRequestError);
  return results.data;
};

export const getAuditLogs = async () => {
  const results = await axios.get('/Security/AuditLogs?numRecords=1000').catch(handleRequestError);
  return results.data;
};

export const getEntityAuditLogs = async (tableName, tablePK, tableSchema) => {
  let url = `/Security/AuditLogs/Entity?tableName=${tableName}&tablePK=${tablePK}`;
  if (tableSchema !== null) {
    url += `&tableSchema=${tableSchema}`;
  }
  const results = await axios.get(url).catch(handleRequestError);
  return results.data;
};

export const getParts = async (locationId) => {
  const queryString = locationId ? `/Maintenance/Part?locationId=${locationId}` : '/Maintenance/Part';
  const results = await axios.get(queryString).catch(handleRequestError);
  return results.data;
};

export const createPartRequest = async (partData) => {
  const results = await axios.post('/Maintenance/Part', partData).catch(handleRequestError);
  return results.data;
};

export const deletePart = async (partId) => {
  const results = await axios.delete(`/Maintenance/Part/${partId}`).catch(handleRequestError);
  return results.data;
};

export const createBulkPurchaseRequests = async (purchaseRequests) => {
  const results = await axios.post('Maintenance/BulkPurchaseRequest', purchaseRequests).catch(handleRequestError);
  return results.data;
};

export const postPurchaseRequest = async (purchaseRequest) => {
  const results = await axios.post('Maintenance/PurchaseRequest', purchaseRequest).catch(handleRequestError);
  return results.data;
};

export const putPurchaseRequest = async (purchaseRequest) => {
  const results = await axios.put(`Maintenance/PurchaseRequest/${purchaseRequest.purchaseRequestId}`, purchaseRequest).catch(handleRequestError);
  return results.data;
};

export const putPurchaseRequestStatus = async (purchaseRequestId, purchaseRequestStatusId) => {
  const results = await axios.put(`Maintenance/PurchaseRequestStatus/${purchaseRequestId}`, { purchaseRequestStatusId }).catch(handleRequestError);
  return results.data;
};

export const assignPurchaseRequestToPurchaseOrder = async (purchaseRequest) => {
  const results = await axios.put('Maintenance/PurchaseRequest/AssignToPurchaseOrder', purchaseRequest);
  return results.data;
};

export const getPurchaseRequests = async () => {
  const results = await axios.get('/Maintenance/PurchaseRequest').catch(handleRequestError);
  return results.data;
};

export const getPurchaseRequestsByPart = async (partId) => {
  const results = await axios.get(`/Maintenance/PurchaseRequest/Part/${partId}`).catch(handleRequestError);
  return results.data;
};

export const getPurchaseRequestsByPurchaseOrder = async (purchaseOrderId) => {
  const results = await axios.get(`/Maintenance/PurchaseRequest/PurchaseOrder/${purchaseOrderId}`).catch(handleRequestError);
  return results.data;
};

export const deletePurchaseRequestRequest = async (purchaseRequestId) => {
  const results = await axios.delete(`/Maintenance/PurchaseRequest/${purchaseRequestId}`).catch(handleRequestError);
  return results.data;
};

export const getPurchaseOrders = async () => {
  const results = await axios.get('/Maintenance/PurchaseOrder').catch(handleRequestError);
  return results.data;
};

export const getPurchaseOrderSingle = async (purchaseOrderId) => {
  const results = await axios.get(`/Maintenance/PurchaseOrder/${purchaseOrderId}`).catch(handleRequestError);
  return results.data;
};

export const postPurchaseOrder = async (purchaseOrder) => {
  const results = await axios.post('Maintenance/PurchaseOrder', purchaseOrder).catch(handleRequestError);
  return results.data;
};

export const getPurchaseOrderPartsPurchased = async (purchaseOrderId) => {
  const results = await axios.get(`/Maintenance/PurchaseOrder/${purchaseOrderId}/PartsPurchased`).catch(handleRequestError);
  return results.data;
};

export const postLogShipment = async (purchaseOrderId, logShipmentRequest) => {
  const results = await axios.post(`Maintenance/PurchaseOrder/${purchaseOrderId}/LogShipment`, logShipmentRequest).catch(handleRequestError);
  return results.data;
};

export const getDewarPricing = async (cst) => {
  const results = await axios.get(`AssetManagement/GetPricingByCST/${cst}`).catch(handleRequestError);
  return results.data;
};
export const getShipmentsByPurchaseOrder = async (purchaseOrderId) => {
  const results = await axios.get(`Maintenance/Shipment/${purchaseOrderId}`).catch(handleRequestError);
  return results.data;
};

export const putPurchaseOrder = async (purchaseOrder) => {
  const results = await axios.put(`Maintenance/PurchaseOrder/${purchaseOrder.purchaseOrderId}`, purchaseOrder).catch(handleRequestError);
  return results.data;
};

export const putPurchaseOrderStatus = async (purchaseOrderId, purchaseOrderStatusId) => {
  const results = await axios.put(`Maintenance/PurchaseOrderStatus/${purchaseOrderId}`, { purchaseOrderStatusId }).catch(handleRequestError);
  return results.data;
};

// remove after trigger
export const triggerTest = async () => {
  const results = await axios.post('/Maintenance/PlannedMaintenanceRequestsGenerator').catch(handleRequestError);
  return results.data;
};

export const getMaintenaceDropdownValues = async () => {
  const results = await axios.get('/Maintenance/DropdownValues').catch(handleRequestError);
  return results.data;
};
export const getMaintenaceDropdownValuesMemoized = memoizePromiseFn(getMaintenaceDropdownValues);

export const getPart = async (partId) => {
  const results = await axios.get(`/Maintenance/Part/${partId}`).catch(handleRequestError);
  return results.data;
};

export const updatePartRequest = async (part) => {
  const results = await axios.put(`/Maintenance/Part/${part.partId}`, part).catch(handleRequestError);
  return results.data;
};

export const reactivatePartRequest = async (partId) => {
  const results = await axios.put(`/Maintenance/ReactivatePart/${partId}`).catch(handleRequestError);
  return results.data;
};

export const getVendors = async () => {
  const results = await axios.get('/Maintenance/Vendor').catch(handleRequestError);
  return results.data;
};

export const getCoordinates = async (address) => {
  const results = await axios.get(`/Customer/GetCoordinates/${address}/`).catch(handleRequestError);
  return results.data;
  /*   const results = await fetch(`http://api.positionstack.com/v1/forward?access_key=f5494cff98e7431f84129f30d619ba70&query=${address}`);
    const result = await results?.json();
    const data = result?.data;
    if (data?.length > 0) {
      return data[0];
    }
    return null; */
};

export const getCOCReports = async () => {
  const results = await axios.get('/Customer/GetCOCReports').catch(handleRequestError);
  return results.data;
};

export const getDeliveryTypes = async (deliveryType) => {
  const results = await axios.get(`/Customer/GetDeliveryTypes/${deliveryType}`).catch(handleRequestError);
  return results.data;
};
