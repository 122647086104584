import React, { useEffect, useState } from 'react';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { checkFormIsDirty } from '../../../services/form-validation';
import { convertDateToUTCDay } from '../../../services/format';
import { selectIsAssetAdmin } from '../../../store/slices/auth-slice';
// import { formatDate } from '../../../services/format';
// import { dateFormatForApi } from '../../../services/user-preferences';
// import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  selectPackagedProductLocations,
} from '../../../store/slices/legacy-slice';
import { updatePackagedProduct } from '../../../store/slices/packaged-products-slice';
import BasicInput from '../../UI/molecules/BasicInput/basic-input';
import Card from '../../UI/molecules/Card/card';
import DateInput from '../../UI/molecules/DateInput/date-input';
import { packagedProductManufacturers } from './packaged-product-values';

function PackagedProductForm({ currentPackagedProduct, packagedProductStatusIsLoading }) {
  const defaultPackagedProductFormValues = {
    serialNumber: '',
    tareWeight: 0,
    currentVolume: 0,
    owningLocationId: null,
    packagedProductType: null,
    isActive: null,
    manufacturer: null,
    manufacturedDate: null,
    isLostOrDestroyed: null,
    isCustomerOwned: null,
    comments: '',
    isOutOfService: null,
    outOfServiceReason: '',
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
    trigger,
  } = useForm({
    defaultValues: defaultPackagedProductFormValues,
  });

  const [formIsDirty, setFormIsDirty] = useState(false);

  const dispatch = useDispatch();
  const onSubmit = (packagedProduct) => {
    Object.keys(packagedProduct).forEach((key) => {
      if (key.toLowerCase().includes('date') && packagedProduct[key]) {
        packagedProduct[key] = convertDateToUTCDay(packagedProduct[key]);
      }
    });
    dispatch(updatePackagedProduct({ ...packagedProduct, assetType: 'PackagedProduct', id: currentPackagedProduct.id }));
    setFormIsDirty(false);
  };

  useEffect(() => {
    if (currentPackagedProduct) {
      Object.keys(defaultPackagedProductFormValues).forEach((key) => {
        if (key.toLowerCase().includes('date') && currentPackagedProduct[key]) {
          setValue(key, new Date(currentPackagedProduct[key].replace('Z', '')));
        } else {
          setValue(key, currentPackagedProduct[key]);
        }
      });
    }
  }, [currentPackagedProduct]);

  const basicInputProps = {
    setValue,
    watch,
    getFieldState,
    register,
    trigger,
  };

  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setFormIsDirty(checkFormIsDirty(value, currentPackagedProduct));
    });
    return () => subscription.unsubscribe();
  }, [watch, currentPackagedProduct]);

  const packagedProductLocations = useSelector(selectPackagedProductLocations);
  const sortedLocations = Object.values(packagedProductLocations)
    .sort((a, b) => a.LocationName.trim().localeCompare(b.LocationName.trim()));
  const packagedProductTypes = useSelector(
    (state) => state.legacy?.dropdownValues?.packagedProductTypes,
  );

  return (
    <>
      <Form className="overflow-auto pt-3 flex-grow-1">
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${packagedProductStatusIsLoading ? ' visible' : ' invisible'}`}
        />
        <fieldset disabled={!isAssetAdmin}>
          <Container
            fluid
            className={`${packagedProductStatusIsLoading ? ' creation-loading' : ''}`}
          >
            <Row>
              <Col>
                <Card
                  header="Info"
                  className="card-secondary card-outline"
                >
                  <Container>
                    <Form.Group className="mb-1">
                      <Form.Label>
                        Serial Number
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        disabled
                        {...register('serialNumber')}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>
                        Product Type
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        {...register('packagedProductType', { required: true })}
                      >
                        {(packagedProductTypes ?? []).map((type) => (
                          <option value={type.id} key={type.id}>{type.ProductNumber}</option>
                        ))}

                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-1">
                      <Form.Label>
                        Owning Location
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        {...register('owningLocationId')}
                      >
                        {(sortedLocations ?? []).map((location) => (
                          <option
                            value={location.LocationID}
                            key={location.LocationID}
                          >
                            {location.LocationName}

                          </option>
                        ))}

                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>
                        Tare Weight
                      </Form.Label>
                      <BasicInput
                        name="tareWeight"
                        defaultUnit="lbs"
                        options={['lbs', 'kgs']}
                        isInvalid={!!errors.tareWeight}
                        {...basicInputProps}
                        initialValue={currentPackagedProduct?.tareWeight}
                        registerOptions={{ required: 'Please enter a valid Tare Weight', min: { value: 0, message: 'must be non-negative' } }}
                        valueIsLoaded={!packagedProductStatusIsLoading}
                      />
                      {errors.tareWeight && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {errors.tareWeight.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-1">
                      <Form.Label>
                        Current Volume
                      </Form.Label>
                      <BasicInput
                        name="currentVolume"
                        defaultUnit="Cubic Feet"
                        options={['Cubic Feet', 'Cubic Meters', 'Liters']}
                        isInvalid={!!errors.currentVolume}
                        {...basicInputProps}
                        initialValue={currentPackagedProduct?.currentVolume}
                        registerOptions={{ required: 'Please enter a Current Volume', min: { value: 0, message: 'must be non-negative' } }}
                        valueIsLoaded={!packagedProductStatusIsLoading}
                      />
                      {errors.currentVolume && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {errors.currentVolume.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Row>
                      <Col>
                        <Form.Label>
                          Manufacturer
                        </Form.Label>
                        <Form.Select
                          size="sm"
                          {...register('manufacturer')}
                        >
                          {(packagedProductManufacturers ?? []).map((manufacturer) => (
                            <option value={manufacturer} key={manufacturer}>{manufacturer}</option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Manufactured Date</Form.Label>
                          <DateInput
                            control={control}
                            formControlName="manufacturedDate"
                            rules={{ required: 'Please enter a Manufactured Date' }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        size="sm"
                        as="textarea"
                        rows={3}
                        {...register('comments')}
                        style={{ resize: 'none' }}
                      />
                    </Form.Group>
                  </Container>
                </Card>
              </Col>
              <Col>
                <Card
                  header="Status"
                  className="card-secondary card-outline"
                >
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Check
                          {...register('isCustomerOwned')}
                          type="switch"
                          inline
                          id="isCustomerOwned-switch"
                          label="Customer Owned"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Check
                          {...register('isLostOrDestroyed')}
                          type="switch"
                          inline
                          id="isLostOrDestroyed-switch"
                          label="Lost/Damaged"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        {/* <Form.Label>Is Active</Form.Label> */}
                        <Form.Check
                          {...register('isActive')}
                          type="switch"
                          inline
                          id="isActive-switch"
                          label="Active"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Check
                          {...register('isOutOfService')}
                          type="switch"
                          inline
                          id="isOutOfService-switch"
                          label="Out of Service"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Out of Service Reason</Form.Label>
                        <Form.Control
                          size="sm"
                          as="textarea"
                          rows={3}
                          {...register('outOfServiceReason')}
                          style={{ resize: 'none' }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

          </Container>
        </fieldset>
      </Form>
      <div className="d-flex flex-row-reverse position-sticky bottom-0 p-2 pr-1 bg-body border-top">
        {isAssetAdmin && (
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={packagedProductStatusIsLoading || !formIsDirty}
          >
            Update
          </Button>
        )}
      </div>
    </>
  );
}

export default PackagedProductForm;
