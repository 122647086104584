import { formatCurrency, formatNumber } from "../../../../services/format";
import { getPastVolumes } from "../../../../services/requests/billing/dewar-billing-requests";
import { DataSource } from "../../../UI/view/data-source";
import { createConnectedListView, ListView, ListViewColumnType } from "../../../UI/view/list-view";
/* public double volume;
public double heVolume;
public int month;
public int year; */
export const pastVolumnesList = {
  columns: [
    {
      id: 'month',
      accessor: ({ month, year }) => `${month}/${year}`,
      Header: 'Date',
    },
    {
      id: 'volume',
      accessor: ({ volume }) => formatCurrency(volume),
      Header: 'Billed ($)',
    },
    {
      id: 'heVolume',
      accessor: ({ heVolume }) => formatNumber(heVolume),
      Header: 'Volume',
    },
  ]
} as ListView;

export const pastVolumesDS = {
  name: 'pastVolumes',
  pk: 'month',
  request: getPastVolumes
} as DataSource;

export const PastVolumesList = createConnectedListView(pastVolumnesList, pastVolumesDS);