import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { checkFormIsDirty } from '../../../services/form-validation';
import { convertDateToUTCDay } from '../../../services/format';
import { selectIsAssetAdmin, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { updateChassis } from '../../../store/slices/chassis-slice';
import { fetchDropdownValues, selectAllActiveLocationObjects } from '../../../store/slices/legacy-slice';
import BasicInput from '../../UI/molecules/BasicInput/basic-input';
import Card from '../../UI/molecules/Card/card';
import DateInput from '../../UI/molecules/DateInput/date-input';
import { Typeahead } from '../../UI/molecules/Typeahead';
import {
  chassisLength, chassisManufacturers, chassisResidentRegion, chassisTypes,
} from './chassis-values';

function ChassisForm({ currentChassis, chassisStatusIsLoading }) {
  const defaultChassisFormValues = {
    AssetType: 'Chassis',
    serialNumber: '',
    ownerCustomer: null,
    weight: null,
    lastDateWeightVerified: null,
    lastWeightVerifiedLocationId: null,
    licensePlate: null,
    kingPinInspectionDate: null,
    aInspectionDate: null,
    aInspectionMileage: null,
    bInspectionDate: null,
    bInspectionMileage: null,
    lastRebuildDate: null,
    odometerReading: null,
    totalMileageAtLastSwapOut: null,
    type: null,
    residentRegion: null,
    length: null,
    isActive: null,
    comments: null,
    isSkikdaAuthorized: null,
    forRareGas: null,
    skikdaAuthorizationDate: null,
    skikdaNextInspectionDate: null,
    telemetryUnitId: null,
    manufacturedYear: null,
    manufacturer: null,
    manufacturerSerialNumber: null,
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
    trigger,
  } = useForm({
    defaultValues: defaultChassisFormValues,
    mode: 'all',
  });

  const dispatch = useDispatch();

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const [formIsDirty, setFormIsDirty] = useState(false);

  const onSubmit = (chassis) => {
    Object.keys(chassis).forEach((key) => {
      if (key.toLowerCase().includes('date') && chassis[key]) {
        chassis[key] = convertDateToUTCDay(chassis[key]);
      }
    });
    dispatch(updateChassis({ ...chassis, assetType: 'Chassis' }));
    setFormIsDirty(false);
  };

  // Populate form with default values
  useEffect(() => {
    if (currentChassis) {
      Object.keys(defaultChassisFormValues).forEach((key) => {
        if (key.toLowerCase().includes('date') && currentChassis[key]) {
          setValue(key, new Date(currentChassis[key].replace('Z', '')));
        } else if (key === 'residentRegion' && currentChassis[key]) {
          setValue(key, currentChassis[key].residentRegion);
        } else {
          setValue(key, currentChassis[key]);
        }
      });
    }
  }, [currentChassis]);

  const chassisCustomers = useSelector(((state) => state.legacy?.dropdownValues?.liquidCustomers))
  ?? [];
  const chassisCustomersStatus = useSelector((state) => state.legacy.status);

  useEffect(() => {
    if (chassisCustomersStatus === 'idle' || chassisCustomersStatus === 'failed') {
      dispatch(fetchDropdownValues());
    }
  }, []);

  const sortedCustomers = useMemo(() => {
    return [...chassisCustomers].filter((cust) => cust.Status === 'Active' || cust.CustomerID === currentChassis?.ownerCustomer?.toString())
      .sort((a, b) => a.CustomerName.trim().localeCompare(b.CustomerName.trim()));
  }, [currentChassis, chassisCustomers]);

  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setFormIsDirty(checkFormIsDirty(value, currentChassis));
    });
    return () => subscription.unsubscribe();
  }, [watch, currentChassis]);

  const basicInputProps = {
    setValue,
    watch,
    getFieldState,
    register,
    trigger,
  };

  const locations = useSelector(selectAllActiveLocationObjects);

  const locationsList = useMemo(() => {
    return Object.values(locations ?? {});
  }, [locations]);

  const initialLastWeightVerifiedLocation = (locations ?? {})[
    currentChassis?.lastWeightVerifiedLocationId ?? ''
  ]?.LocationName ?? '';

  return (
    <>
      <Form className="overflow-auto pt-3 flex-grow-1">
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${chassisStatusIsLoading ? ' visible' : ' invisible'}`}
        />
        <fieldset disabled={!isAssetAdmin}>
          <Container
            fluid
            className={`${chassisStatusIsLoading ? ' creation-loading' : ''}`}
          >
            <Row>
              <Col>
                <Card
                  header="Info"
                  className="card-secondary card-outline"
                >
                  <Form.Group className="mb-1">
                    <Form.Label>Owner</Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('ownerCustomer', { required: 'Please select an owner.' })}
                      isInvalid={!!errors.ownerCustomer}
                    >
                      {sortedCustomers
                        .map((customer, index) => (
                          <option value={customer.CustomerID} key={`customer-${index}`}>{customer.CustomerName}</option>
                        ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                    {errors.ownerCustomer && errors.ownerCustomer.message}
                  </Form.Control.Feedback> */}
                  </Form.Group>
                  <Container className="p-0 m-0">
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Weight</Form.Label>
                          <BasicInput
                            name="weight"
                            defaultUnit="lbs"
                            options={['lbs', 'kgs']}
                            isInvalid={!!errors.weight}
                            {...basicInputProps}
                            initialValue={currentChassis?.weight}
                            registerOptions={{ required: 'Please enter a valid Weight', min: { value: 0, message: 'must be non-negative' } }}
                            valueIsLoaded={!chassisStatusIsLoading}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.weight && errors.weight.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Unit Number</Form.Label>
                          <Form.Control
                            size="sm"
                            disabled
                            {...register('serialNumber')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>License Plate</Form.Label>
                          <Form.Control
                            size="sm"
                            {...register('licensePlate')}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Last Date Weight Verified</Form.Label>
                          <DateInput
                            control={control}
                            formControlName="lastDateWeightVerified"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Last Weight Verified Location</Form.Label>
                          {/* <Form.Control
                            size="sm"
                            {...register('lastWeightVerifiedLocationId')}
                          /> */}
                          {(initialLastWeightVerifiedLocation
                           || currentChassis?.lastWeightVerifiedLocationId === null) && (
                           <Controller
                             control={control}
                             name="lastWeightVerifiedLocationId"
                             defaultValue={null}
                             render={({
                               field: {
                                 onChange, value, ref,
                               },
                               fieldState: {
                                 invalid, isTouched, isDirty, error,
                               },
                               formState,
                             }) => (
                               <Typeahead
                                 ref={ref}
                        // value={value}
                                 flip
                                 onChange={(e) => {
                                   onChange(locationsList?.find(
                                     (l) => l?.LocationName === e[0],
                                   )?.LocationID);
                                 }}
                                 size="sm"
                                 id="returnToFacility"
                                 options={locationsList.map((l) => l.LocationName)}
                                 defaultSelected={[initialLastWeightVerifiedLocation]}
                                 positionFixed
                                 isInvalid={!!error}
                               />
                             )}
                           />
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Manufacturer</Form.Label>
                          <Form.Select
                            size="sm"
                            {...register('manufacturer')}
                          >
                            {chassisManufacturers.map((manufacturer, i) => (
                              <option
                                value={manufacturer}
                                key={`manufacturer-${manufacturer}`}
                              >
                                {manufacturer}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Manufactured Year</Form.Label>
                          <Form.Control
                            size="sm"
                            {...register('manufacturedYear')}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-1">
                      <Form.Label>Manufacturer Serial Number</Form.Label>
                      <Form.Control
                        size="sm"
                        {...register('manufacturerSerialNumber')}
                      />
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Type</Form.Label>
                          <Form.Select
                            size="sm"
                            {...register('type')}
                          >
                            {chassisTypes.map((type, i) => (
                              <option
                                value={i}
                                key={`type-${type}`}
                              >
                                {type}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Length</Form.Label>
                          <Form.Select
                            size="sm"
                            {...register('length')}
                          >
                            {chassisLength.map((type, i) => (
                              <option
                                value={i}
                                key={`length-${type}`}
                              >
                                {type}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Resident Region</Form.Label>
                          <Form.Select
                            size="sm"
                            {...register('residentRegion', { required: 'Please select a resident region.' })}
                            isInvalid={!!errors.residentRegion}
                          >
                            {chassisResidentRegion.map((type, i) => (
                              <option
                                value={i}
                                key={`resident-region-${type}`}
                              >
                                {type}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <Form.Control.Feedback type="invalid">
                          {errors.residentRegion && errors.residentRegion.message}
                        </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        size="sm"
                        as="textarea"
                        rows={3}
                        {...register('comments')}
                        style={{ resize: 'none' }}
                      />
                    </Form.Group>
                  </Container>
                </Card>
              </Col>
              <Col>
                <Card
                  header="Skikda Information"
                  className="card-secondary card-outline"
                >
                  <Form.Check
                    {...register('isSkikdaAuthorized')}
                    inline
                    type="switch"
                    id="isSkikdaAuthorized-switch"
                    label="Skikda Authorized"
                  />
                  <Form.Group className="mb-1">
                    <Form.Label>Skikda Authoization Date</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="skikdaAuthorizationDate"
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Skikda Next Inspection Date</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="skikdaNextInspectionDate"
                    />
                  </Form.Group>
                </Card>
                <Card
                  header="Status"
                  className="card-secondary card-outline"
                >

                  <Form.Check
                    {...register('isActive')}
                    type="switch"
                    inline
                    id="isActive-switch"
                    label="Active"
                  />
                  <Form.Check
                    {...register('forRareGas')}
                    inline
                    type="switch"
                    id="forRareGas-switch"
                    label="For Rare Gas"
                  />

                </Card>
                <Card
                  header="Maintenance Items"
                  className="mt-2 card-secondary card-outline"
                >
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>King Pin Inspection Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="kingPinInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>IoT Device ID</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('telemetryUnitId')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>A Inspection Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="aInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>A Inspection Mileage</Form.Label>
                        <Form.Control
                          size="sm"
                          type="number"
                          min="0"
                          {...register(
                            'aInspectionMileage',
                            { min: { value: 0, message: 'must be non-negative' } },
                          )}
                          isInvalid={!!errors.aInspectionMileage}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.aInspectionMileage && errors.aInspectionMileage.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>DOT Inspection Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="bInspectionDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>DOT Inspection Mileage</Form.Label>
                        {/* <BasicInput
                        name="bInspectionMileage"
                        defaultUnit="Miles"
                        options={['Miles', 'Kilometers']}
                        isInvalid={!!errors.bInspectionMileage}
                        {...basicInputProps}
                        initialValue={currentChassis?.bInspectionMileage}
                        valueIsLoaded={!chassisStatusIsLoading}
                      /> */}
                        <Form.Control
                          size="sm"
                          type="number"
                          min="0"
                          {...register(
                            'bInspectionMileage',
                            { min: { value: 0, message: 'must be non-negative' } },
                          )}
                          isInvalid={!!errors.bInspectionMileage}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.bInspectionMileage && errors.bInspectionMileage.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Odometer Reading</Form.Label>
                        <Form.Control
                          size="sm"
                          type="number"
                          min="0"
                          {...register(
                            'odometerReading',
                            { min: { value: 0, message: 'Please enter a valid odometer reading. (Non-negative)' } },
                          )}
                          isInvalid={!!errors.odometerReading}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.odometerReading && errors.odometerReading.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Total Mileage at Last Swap Out</Form.Label>
                        <Form.Control
                          size="sm"
                          type="number"
                          min="0"
                          isInvalid={!!errors.totalMileageAtLastSwapOut}
                          {...register('totalMileageAtLastSwapOut', { min: { value: 0, message: 'must be non-negative' } })}
                          style={{ resize: 'none' }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.totalMileageAtLastSwapOut
                          && errors.totalMileageAtLastSwapOut.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-1">
                    <Form.Label>Last Rebuild</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="lastRebuildDate"
                    />
                  </Form.Group>
                </Card>
              </Col>
            </Row>
          </Container>
        </fieldset>
      </Form>
      <div className="d-flex flex-row-reverse position-sticky bottom-0 p-2 pr-1 bg-body border-top">
        {isAssetAdmin && (
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={chassisStatusIsLoading || !formIsDirty}
          >
            Update
          </Button>
        )}
      </div>
    </>
  );
}

export default ChassisForm;
