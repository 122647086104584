import React, { useMemo } from "react"
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

export const SelectFilter = ({handleFilterChange, selector, filter, data}) => {

  let optionsSel = useSelector(selector ? selector : () => null) as any[];

  const options = useMemo(() => {
    if(selector) return optionsSel;
    const list = Array.from([...new Set(data?.map(d => d[filter.fieldName]))]).sort((a,b) => {
      if(a?.toString().trim() < b?.toString().trim()) return -1;
      if(a?.toString().trim() > b?.toString().trim()) return 1;
      return 0;
    });
    return list;
  }, [data, optionsSel]);


  return (
    <Form.Group className="me-3 mb-1">
      <Form.Select size="sm" onChange={(e) => { handleFilterChange(e.target.value, filter.fieldName); }}>
        <option value="">-- {filter.label} --</option>
        {options.map((type, i) => (
          <option value={type} key={type}>{type}</option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}