import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  createGasAttachmentRequest, createGasContainerRequest, deleteGasAttachment,
  getGasAndChassisReadings,
  getGasContainer,
  getGasContainers,
  getMaintenanceStatusForAssets,
  updateGasContainerRequest,
} from '../../services/requests';

const initialState = {
  gasContainers: {},
  fetchGasAndChassisReadings: {
    status: 'idle',
    error: null,
  },
  gasContainersFetch: {
    status: 'idle',
    error: null,
  },
  gasContainersFetchSingle: {
    status: 'idle',
    error: null,
  },
  currentGasContainer: {
    gasContainerId: null,
    status: 'idle',
    error: null,
  },
  gasContainerCreate: {
    status: 'idle',
    error: null,
  },
  gasContainerUpdate: {
    status: 'idle',
    error: null,
  },
  gasContainerAttachmentCreate: {
    status: 'idle',
    error: null,
  },
  gasContainerAttachmentRemove: {
    status: 'idle',
    error: null,
  },
  gasContainerMaintenanceStatuses: {},
  gasContainersMaintenanceStatusFetch: {
    status: 'idle',
    error: null,
  },
};

export const fetchGasAndChassisReadings = createAsyncThunk('gasContainer/fetchLiquidContainerReadings', async (arg) => {
  const { serialNumber, range, assetType } = arg;
  const response = await getGasAndChassisReadings(assetType, serialNumber, range);
  return response;
});

export const fetchGasContainers = createAsyncThunk('gasContainer/fetchGasContainers', async () => {
  const response = await getGasContainers();
  return response;
});

export const fetchGasContainer = createAsyncThunk('gasContainer/fetchGasContainer', async (serialNumber) => {
  const response = await getGasContainer(serialNumber);
  return response;
});

export const createGasContainer = createAsyncThunk('gasContainer/createGasContainer', async (user) => {
  const response = await createGasContainerRequest(user);
  return response;
});

export const updateGasContainer = createAsyncThunk('gasContainer/updateGasContainer', async (data, thunkAPI) => {
  const response = await updateGasContainerRequest(data);
  return response;
});

export const createGasAttachment = createAsyncThunk('attachment/createGasAttachment', async (attachment, thunkAPI) => {
  const response = await createGasAttachmentRequest(attachment);
  return response;
});

export const removeGasAttachment = createAsyncThunk('attachment/deleteGasAttachment', async (attachmentId, thunkAPI) => {
  const response = await deleteGasAttachment(attachmentId);
  return response;
});

export const fetchMaintenanceStatusForGasContainers = createAsyncThunk(
  'gasContainer/fetchMaintenanceStatusForGasContainers',
  async ({ assetTypeId, assetIds }) => {
    const response = await getMaintenanceStatusForAssets(assetTypeId, assetIds);
    return response;
  },
);

export const gasContainerSlice = createSlice({
  name: 'gasContainer',
  initialState,
  reducers: {
    setGasContainers: (state, action) => {
      state.gasContainers = action.payload.reduce((current, g) => (
        { ...current, [g.serialNumber]: g }), {});
    },
    setCurrentGasContainerId: (state, action) => {
      state.currentGasContainer.gasContainerId = action.payload;
    },
    setGasContainerUpdateStatus: (state, action) => {
      state.gasContainerUpdate.status = action.payload;
    },
    setGasContainerCreateStatus: (state, action) => {
      state.gasContainerCreate.status = action.payload;
    },
    setCreateAttachmentStatus: (state, action) => {
      state.gasContainerAttachmentCreate.status = action.payload;
    },
    setRemoveAttachmentStatus: (state, action) => {
      state.gasContainerAttachmentRemove.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGasAndChassisReadings.pending, (state, action) => {
        state.fetchGasAndChassisReadings.status = 'loading';
      })
      .addCase(fetchGasAndChassisReadings.fulfilled, (state, action) => {
        state.fetchGasAndChassisReadings.status = 'succeeded';
        state.gasReadings = {
          ...state.gasReadings,
          [state.currentGasContainer.gasContainerId]: {
            ...action.payload,
          },
        };
      })
      .addCase(fetchGasAndChassisReadings.rejected, (state, action) => {
        state.fetchGasAndChassisReadings.status = 'failed';
        state.fetchGasAndChassisReadings.error = action.error.message;
      });
    builder
      .addCase(fetchGasContainers.pending, (state, action) => {
        state.gasContainersFetch.status = 'loading';
      })
      .addCase(fetchGasContainers.fulfilled, (state, action) => {
        state.gasContainersFetch.status = 'succeeded';
        // state.gasContainers = state.gasContainers.concat(action.payload)
        state.gasContainers = action.payload.reduce((current, p) => (
          { ...current, [p.serialNumber]: p }), {});
      })
      .addCase(fetchGasContainers.rejected, (state, action) => {
        state.gasContainersFetch.status = 'failed';
        state.gasContainersFetcherror = action.error.message;
      });
    builder
      .addCase(fetchGasContainer.pending, (state, action) => {
        state.gasContainersFetchSingle.status = 'loading';
      })
      .addCase(fetchGasContainer.fulfilled, (state, action) => {
        state.gasContainersFetchSingle.status = 'succeeded';
        state.gasContainers = {
          ...state.gasContainers,
          [action.payload.assetDetails.serialNumber]: {
            ...action.payload.assetDetails,
            attachmentList: action.payload.attachmentList,
          },
        };
      })
      .addCase(fetchGasContainer.rejected, (state, action) => {
        state.gasContainersFetchSingle.status = 'failed';
        state.gasContainersFetchSingle.error = action.error.message;
      });
    builder
      .addCase(createGasAttachment.pending, (state, action) => {
        state.gasContainerAttachmentCreate.status = 'loading';
      })
      .addCase(createGasAttachment.fulfilled, (state, action) => {
        state.gasContainerAttachmentCreate.status = 'succeeded';
        const newAttachmentList = JSON.parse(JSON.stringify([...state.gasContainers[state.currentGasContainer.gasContainerId].attachmentList]));
        const exists = newAttachmentList.filter((x) => x.attachmentId === action.payload.attachmentId);
        if (exists.length === 0) newAttachmentList.push(action.payload);
        state.gasContainers = {
          ...state.gasContainers,
          [state.currentGasContainer.gasContainerId]: {
            ...state.gasContainers[state.currentGasContainer.gasContainerId],
            attachmentList:
              [
                ...newAttachmentList,
              ],
          },
        };
      })
      .addCase(createGasAttachment.rejected, (state, action) => {
        state.gasContainerAttachmentCreate.status = 'failed';
        state.gasContainerAttachmentCreate.error = action.error.message;
      });

    builder
      .addCase(removeGasAttachment.pending, (state, action) => {
        state.gasContainerAttachmentRemove.status = 'loading';
      })
      .addCase(removeGasAttachment.fulfilled, (state, action) => {
        state.gasContainerAttachmentRemove.status = 'succeeded';
        state.gasContainers = {
          ...state.gasContainers,
          [state.currentGasContainer.gasContainerId]: {
            ...state.gasContainers[state.currentGasContainer.gasContainerId],
            attachmentList:
              state.gasContainers[state.currentGasContainer.gasContainerId]
                .attachmentList
                .filter((attachment) => attachment.attachmentId !== action.meta.arg),
          },
        };
      })
      .addCase(removeGasAttachment.rejected, (state, action) => {
        state.gasContainerAttachmentRemove.status = 'failed';
        state.gasContainerAttachmentRemove.error = action.error.message;
      });

    builder
      .addCase(createGasContainer.pending, (state, action) => {
        state.gasContainerCreate.status = 'loading';
      })
      .addCase(createGasContainer.fulfilled, (state, action) => {
        state.gasContainerCreate.status = 'succeeded';
        state.gasContainers = {
          ...state.gasContainers,
          [action.payload.serialNumber]: action.payload,
        };
        state.currentGasContainer.gasContainerId = action.payload.serialNumber;
        state.gasContainerCreate.error = null;
      })
      .addCase(createGasContainer.rejected, (state, action) => {
        state.gasContainerCreate.status = 'failed';
        state.gasContainerCreate.error = action.error.message;
      });

    builder
      .addCase(updateGasContainer.pending, (state, action) => {
        state.gasContainerUpdate.status = 'loading';
      })
      .addCase(updateGasContainer.fulfilled, (state, action) => {
        state.gasContainerUpdate.status = 'succeeded';
        state.gasContainers = {
          ...state.gasContainers,
          [action.payload.serialNumber]: {
            ...state.gasContainers[action.payload.serialNumber],
            ...action.payload,
          },
        };
        state.gasContainerUpdate.error = null;
      })
      .addCase(updateGasContainer.rejected, (state, action) => {
        state.gasContainerUpdate.status = 'failed';
        state.gasContainerUpdate.error = action.error.message;
      });

    builder
      .addCase(fetchMaintenanceStatusForGasContainers.pending, (state, action) => {
        state.gasContainersMaintenanceStatusFetch.status = 'loading';
      })
      .addCase(fetchMaintenanceStatusForGasContainers.fulfilled, (state, action) => {
        state.gasContainersMaintenanceStatusFetch.status = 'succeeded';
        state.gasContainerMaintenanceStatuses = {
          ...state.gasContainerMaintenanceStatuses,
          ...action.payload,
        };
        state.gasContainersMaintenanceStatusFetch.error = null;
      })
      .addCase(fetchMaintenanceStatusForGasContainers.rejected, (state, action) => {
        state.gasContainersMaintenanceStatusFetch.status = 'failed';
        state.gasContainersMaintenanceStatusFetch.error = action.error.message;
      });
  },
});

export const {
  setGasContainers, setCurrentGasContainerId,
  setGasContainerUpdateStatus, setGasContainerCreateStatus,
  setCreateAttachmentStatus, setRemoveAttachmentStatus,
} = gasContainerSlice.actions;

export const selectGasContainers = (state) => state.gasContainer.gasContainers;
export const selectCurrentGasContainerId = (state) => (
  state.gasContainer.currentGasContainer.gasContainerId
);

export const selectGasContainersMaintenanceStatuses = (
  state,
) => state.gasContainer.gasContainerMaintenanceStatuses;

export const selectCurrentGasContainer = createSelector([
  selectGasContainers,
  selectCurrentGasContainerId], (gasContainers, gasContainerId) => {
  if (gasContainerId == null) {
    return {};
  }
  return gasContainers[gasContainerId];
});

export const selectAllGasReadings = (state) => state.gasContainer.gasReadings;

export const selectCurrentGasReadings = createSelector([
  selectAllGasReadings,
  selectCurrentGasContainerId], (containers, containerId) => {
  if (!containerId || !containers || !containers[containerId]) {
    return {
      pr: [], myTemp: [], myBattV: [], myLoc: [], myReadings: [],
    };
  }
  return containers[containerId];
});

export default gasContainerSlice.reducer;
